import styles from './style/AlertSwitch.module.scss';

const AlertSwitch = ({ isChecked, onChange, isTransparent, color = { on: '#333333', off: '#dddddd' }, disabled = false }) => {
  return (
    <label className={`${styles.switch} ${disabled ? styles.disabled : ''}`}>
      <input type='checkbox' checked={isChecked} onChange={!disabled ? () => onChange() : () => {}} />
      <span
        className={isTransparent ? styles.sliderTransparent : styles.slider}
        style={{
          backgroundColor: isChecked ? color.on : color.off,
        }}
      />
    </label>
  );
};

export default AlertSwitch;
