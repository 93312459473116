import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "../Utilities/Button";
import Modal from "../Utilities/Modal";
import { IoAddOutline } from "react-icons/io5";
import {
  MdOutlineUploadFile,
  MdDriveFileRenameOutline,
  MdDeleteOutline,
} from "react-icons/md";
import { BsPinAngleFill } from "react-icons/bs";
import { IoIosArrowBack } from "react-icons/io";
import Alert from "../Utilities/Alert";
import Pagination from "../Utilities/PaginationNew";
import InputSearch from "../Utilities/InputSearch";
import Datepicker from "../Utilities/Datepicker/Datepicker";
import { format } from "date-fns";
import Dropdown from "../Utilities/DropdownDynamic";
import AlertSwitch from "../Utilities/AlertSwitch/EnterpriseAlertSwitch";
import { selectLeftNavigate } from "../../store/slice/navigate";
import Template01 from "../../assets/template01.jpg";
import Template02 from "../../assets/template02.jpg";
import {
  Template1Editor,
  Template2Editor,
  Template3Editor,
  Template4Editor,
  Template5Editor,
  Template6Editor,
} from "./Editor";
import { ClipLoader } from "react-spinners";
import {
  selectEnterprise,
  getEnterpriseArticle,
  addArticle,
  editArticle,
  getAloneEnterpriseArticle,
  displayArticle,
  deleteArticle,
  closeLoading,
  changeIsEnterprise,
} from "../../store/slice/enterpriseLists";
import { selectPermissionSettings } from "../../store/slice/permissionSettings";
import styles from "./style/Enterprise.module.scss";
import InnerLayout from "../Layout/InnerLayout";

const truncateTitle = (text) => {
  const screenWidth = window.innerWidth;

  let truncateLength = 25;

  if (screenWidth <= 1440) {
    truncateLength = 20;
  }

  if (screenWidth <= 1280) {
    truncateLength = 18;
  }

  if (screenWidth <= 1024) {
    truncateLength = 12;
  }

  if (text.length > truncateLength) {
    return text.substring(0, truncateLength) + "...";
  } else {
    return text;
  }
};

const editDateConvertFormat = (editDate) => {
  const dateString = editDate;

  const date = new Date(dateString.replace(" ", "T"));

  const startDay = new Date(date);
  startDay.setHours(0, 0, 0, 0);

  const year = startDay.getFullYear();
  const month = startDay.getMonth() + 1;
  const day = startDay.getDate();
  const hour = date.getHours().toString().padStart(2, "0");
  const min = date.getMinutes().toString().padStart(2, "0");
  const sec = date.getSeconds().toString().padStart(2, "0");

  const formattedDate = `${year}/${month}/${day}`;

  const result = {
    startDay: startDay.toString(),
    date: formattedDate,
    hour: hour,
    min: min,
    sec: sec,
  };

  return result;
};

const addImgName = (imgData) => {
  let image = imgData;
  let updatedCover_image = {
    ...image,
    name: image.file_name,
  };
  return updatedCover_image;
};

const addTemplateId = (allArticleData) => {
  return allArticleData.map((item) => {
    return {
      ...item,
      templateId: Number(item.template),
    };
  });
};

const checkImages = (newApiArticle) => {
  const targetImages = [
    "/static/media/template02.f069df6ffd63ff0883a1.jpg",
    "/static/media/template01.713428199199d0e8d5f9.jpg",
  ];

  for (const article of newApiArticle) {
    if (Array.isArray(article.images)) {
      for (const image of article.images) {
        if (targetImages.includes(image)) {
          return true;
        }
      }
    }
  }

  return false;
};

const Enterprise = () => {
  const dispatch = useDispatch();

  const { enterpriseShareLoading, enterpriseShareList } =
    useSelector(selectEnterprise);
    

  const { auth } = useSelector(selectPermissionSettings);
  const navigate = useNavigate();

  const [saveDataError, setSaveDataError] = useState(false);

  const [editArticleError, setEditArticleError] = useState(false);

  const toggleLeftNavigation = useSelector(selectLeftNavigate);
  const leftNavigationShow = toggleLeftNavigation.toggleOpen;

  const [isPreviewArticleModal, setIsPreviewArticleModal] = useState(false);

  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");

  const [isDeleteItem, setIsDeleteItem] = useState(false);
  const [deleteItem, setDeleteItem] = useState({ id: 0, name: "" });

  const [fetchSearchValue, setFetchSearchValue] = useState("");

  const [listData, setListData] = useState([]);

  const [editId, setEditId] = useState(0);

  const [allArticleData, setAllArticleData] = useState([]);

  const [isAddArticleError, setIsAddArticleError] = useState(false);

  const [isAddUpdataArticleImgError, setIsAddUpdataArticleImgError] =
    useState(false);

  const [isAddArticleContent, setIsAddArticleContent] = useState(false);

  const [isEditArticleContent, setIsEditArticleContent] = useState(false);

  const [isAddTemplateModal, setIsAddTemplateModal] = useState(false);

  const [articleTitle, setArticleTitle] = useState("");

  const [errorUploadCover, setErrorUploadCover] = useState("");
  const [coverFiles, setCoverFiles] = useState(null);

  const [errorUploadLogo, setErrorUploadLogo] = useState("");
  const [logoFiles, setLogoFiles] = useState(null);

  const [errorUploadDesktop, setErrorUploadDesktop] = useState("");
  const [desktopFiles, setDesktopFiles] = useState(null);

  const [errorUploadMobile, setErrorUploadMobile] = useState("");
  const [mobileFiles, setMobileFiles] = useState(null);

  const [isErrorUploadImg, setIsErrorUploadImg] = useState(false);

  const [planDate, setPlanDate] = useState({
    startDay: "",
    date: "",
    hour: "",
    min: "",
    sec: "00",
  });

  const [dateError, setDateError] = useState(false);

  const [isPinToTop, setIsPinToTop] = useState(false);

  const [selectedDiv, setSelectedDiv] = useState(0);

  const limit = 5;

  const clearAllOption = () => {
    setSaveDataError(false);
    setCoverFiles(null);
    setLogoFiles(null);
    setDesktopFiles(null);
    setMobileFiles(null);
    setIsErrorUploadImg(false);
    setIsPinToTop(false);
    setEditArticleError(false);
    setErrorUploadLogo("");
    setPlanDate({
      startDay: "",
      date: "",
      hour: "",
      min: "",
      sec: "00",
    });
    setEditId(0);
    setArticleTitle("");
    setIsEditArticleContent(false);
    dispatch(changeIsEnterprise(false));
    setIsAddArticleContent(false);
    setIsAddUpdataArticleImgError(false);
    setIsAddArticleError(false);
    setAllArticleData([]);
  };

  useEffect(() => {
    if (enterpriseShareList?.length === 0) {
      return;
    }
    setListData(enterpriseShareList?.rows);
  }, [enterpriseShareList]);

  const handleTemplateClick = (id) => {
    setSelectedDiv(id);
  };

  const dropdownHourList = [
    { hour: "00" },
    { hour: "01" },
    { hour: "02" },
    { hour: "03" },
    { hour: "04" },
    { hour: "05" },
    { hour: "06" },
    { hour: "07" },
    { hour: "08" },
    { hour: "09" },
    { hour: "10" },
    { hour: "11" },
    { hour: "12" },
    { hour: "13" },
    { hour: "14" },
    { hour: "15" },
    { hour: "16" },
    { hour: "17" },
    { hour: "18" },
    { hour: "19" },
    { hour: "20" },
    { hour: "21" },
    { hour: "22" },
    { hour: "23" },
  ];
  const dropdownMinList = [
    { min: "00" },
    { min: "15" },
    { min: "30" },
    { min: "45" },
  ];

  const handleEnterApi = (e) => {
    if (e.code === "Enter" || e.charCode === 13) {
      setFetchSearchValue(searchValue);
      setPage(1);

      let postObj;

      postObj = {
        page: 1,
        limit: limit,
        search: searchValue,
      };

      dispatch(getEnterpriseArticle(postObj))
        .then((e) => {
          if (e?.payload === undefined) {
            return Promise.reject("Payload is undefined");
          }
        })
        .catch((err) => {
          setSearchValue("");
          setFetchSearchValue("");
          dispatch(closeLoading());
          console.error("Error:", err);
        });
    }
  };

  const handleStartDay = (date) => {
    const startDay = new Date(date);
    return format(startDay.setDate(startDay.getDate() - 1), "yyyy-MM-dd");
  };

  const getUploadFileCover = (e) => {
    setErrorUploadCover("");
    let uploadFiles = e?.target.files;

    if (uploadFiles) {
      for (let i = 0; i < uploadFiles.length; i++) {
        const data = uploadFiles[i];
        if (!(data.name.endsWith(".png") || data.name.endsWith(".jpg"))) {
          setErrorUploadCover("type");
          setCoverFiles(null);
        } else if (data.size / 1000000 > 9.9) {
          setErrorUploadCover("size");
          setCoverFiles(null);
        } else {
          setCoverFiles(data);
        }
      }
    }
    setTimeout(() => {
      e.target.value = "";
    }, 500);
  };

  const getUploadFileLogo = (e) => {
    setErrorUploadLogo("");
    let uploadFiles = e?.target.files;

    if (uploadFiles) {
      for (let i = 0; i < uploadFiles.length; i++) {
        const data = uploadFiles[i];
        if (!(data.name.endsWith(".png") || data.name.endsWith(".jpg"))) {
          setErrorUploadLogo("type");
          setLogoFiles(null);
        } else if (data.size / 1000000 > 9.9) {
          setErrorUploadLogo("size");
          setLogoFiles(null);
        } else {
          setLogoFiles(data);
        }
      }
    }
    setTimeout(() => {
      e.target.value = "";
    }, 500);
  };

  const getUploadFileDesktop = (e) => {
    setErrorUploadDesktop("");
    let uploadFiles = e?.target.files;

    if (uploadFiles) {
      for (let i = 0; i < uploadFiles.length; i++) {
        const data = uploadFiles[i];
        if (!(data.name.endsWith(".png") || data.name.endsWith(".jpg"))) {
          setErrorUploadDesktop("type");
          setDesktopFiles(null);
        } else if (data.size / 1000000 > 9.9) {
          setErrorUploadDesktop("size");
          setDesktopFiles(null);
        } else {
          setDesktopFiles(data);
        }
      }
    }
    setTimeout(() => {
      e.target.value = "";
    }, 500);
  };

  const getUploadFileMobile = (e) => {
    setErrorUploadMobile("");
    let uploadFiles = e?.target.files;

    if (uploadFiles) {
      for (let i = 0; i < uploadFiles.length; i++) {
        const data = uploadFiles[i];
        if (!(data.name.endsWith(".png") || data.name.endsWith(".jpg"))) {
          setErrorUploadMobile("type");
          setMobileFiles(null);
        } else if (data.size / 1000000 > 9.9) {
          setErrorUploadMobile("size");
          setMobileFiles(null);
        } else {
          setMobileFiles(data);
        }
      }
    }
    setTimeout(() => {
      e.target.value = "";
    }, 500);
  };

  const formatDate = (planDate) => {
    if (!planDate?.startDay) {
      return ``;
    }

    const date = new Date(planDate?.startDay);

    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();

    return `${month}月${day}日,${year}`;
  };

  const formatDateTime = (date, hour, min, sec) => {
    if (date === "" || hour === "" || min === "" || sec === "") {
      return;
    }
    if (!date || !date.includes("/")) {
      return;
    }
    const [year, month, day] = date.split("/");
    const formattedDate = `${year}-${String(month).padStart(2, "0")}-${String(
      day
    ).padStart(2, "0")}`;

    const formattedTime = `${String(hour).padStart(2, "0")}:${String(
      min
    ).padStart(2, "0")}:${String(sec).padStart(2, "0")}`;

    return `${formattedDate} ${formattedTime}`;
  };

  const pageChangeHandler = (e) => {
    setPage(parseInt(e.selected + 1));
    let postObj;
    if (fetchSearchValue !== "") {
      postObj = {
        page: e.selected + 1,
        limit: limit,
        search: fetchSearchValue,
      };
    } else {
      postObj = {
        page: e.selected + 1,
        limit: limit,
      };
    }
    dispatch(getEnterpriseArticle(postObj));
  };

  useEffect(() => {   
    if (!auth) {
      return;
    }
    if (auth && !auth?.roles.find((item) => item === "workspace.tdp")) {
      navigate("/");
      return;
    }
    let postObj;

    if (fetchSearchValue !== "") {
      postObj = {
        page: 1,
        limit: limit,
        search: fetchSearchValue,
      };
    } else {
      postObj = {
        page: 1,
        limit: limit,
      };
    }
    dispatch(getEnterpriseArticle(postObj))
      .then((e) => {
        if (e?.payload === undefined) {
          return Promise.reject("Payload is undefined");
        }
      })
      .catch((err) => {
        setSearchValue("");
        setFetchSearchValue("");
        dispatch(closeLoading());
        console.error("Error:", err);
      });
  }, [auth, fetchSearchValue, dispatch, navigate]);

  const handleSaveColor = (index, color) => {
    const newData = [...allArticleData];
    if (color !== undefined) {
      newData[index].background_color = color;
    }
    setAllArticleData(newData);
  };

  const handleSaveLabelColor = (index, idx, color) => {
    const newData = [...allArticleData];
    if (color !== undefined) {
      newData[index].label[idx] = color;
    }
    setAllArticleData(newData);
  };

  const handleImageChange = (index, e, setPreviewImage) => {
    const file = e.target.files[0];
    const newData = [...allArticleData];
    setIsAddUpdataArticleImgError(false);

    if (!(file.name.endsWith(".png") || file.name.endsWith(".jpg"))) {
      setIsErrorUploadImg(true);
      return;
    } else if (file.size / 1000000 > 9.9) {
      setIsErrorUploadImg(true);
      return;
    }

    if (file) {
      setIsErrorUploadImg(false);
      const reader = new FileReader();
      reader.onloadend = () => {
        newData[index].img = [reader.result];
        newData[index].images = [file];
        setPreviewImage([reader.result]);
      };
      reader.readAsDataURL(file);
    }
    setAllArticleData(newData);
  };

  const handleImageChangeMore = (index, e, setPreviewImage, idx) => {
    const file = e.target.files[0];
    const newData = [...allArticleData];
    setIsAddUpdataArticleImgError(false);

    if (!(file.name.endsWith(".png") || file.name.endsWith(".jpg"))) {
      setIsErrorUploadImg(true);
      return;
    } else if (file.size / 1000000 > 9.9) {
      setIsErrorUploadImg(true);
      return;
    }

    if (file) {
      setIsErrorUploadImg(false);
      const reader = new FileReader();
      reader.onloadend = () => {
        const newImageArray = [...newData[index].img];
        newImageArray[idx] = reader.result;
        newData[index].images[idx] = file;
        newData[index].img = newImageArray;

        setPreviewImage(newImageArray);
      };
      reader.readAsDataURL(file);
    }
    setAllArticleData(newData);
  };

  const handleSaveTemplate1 = (index, updatedMessage) => {
    const newData = [...allArticleData];
    if (updatedMessage !== undefined) {
      newData[index].content = updatedMessage;
    }
    setAllArticleData(newData);
  };

  const handleSaveTemplate2 = (
    index,
    updatedTitleMessage,
    updatedContentMessage
  ) => {
    const newData = [...allArticleData];
    if (updatedTitleMessage !== undefined) {
      newData[index].sub_title = updatedTitleMessage;
    }
    if (updatedContentMessage !== undefined) {
      newData[index].content = updatedContentMessage;
    }
    setAllArticleData(newData);
  };

  const handleSaveTemplate5 = (index, updatedTitleMessage) => {
    const newData = [...allArticleData];
    if (updatedTitleMessage !== undefined) {
      newData[index].sub_title = updatedTitleMessage;
    }
    setAllArticleData(newData);
  };

  const addSelectData = (selectedDiv) => {
    let addDiv;
    if (selectedDiv === 1) {
      addDiv = {
        background_color: `#ffffff`,
        templateId: 1,
        template: "1",
        content: [
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse.",
        ],
      };
    } else if (selectedDiv === 2) {
      addDiv = {
        background_color: `#ffffff`,
        templateId: 2,
        template: "2",
        sub_title: [
          `<p style="text-align:center;font-size:
        28px;font-weight: 700;color:#333333;letter-spacing: 0.07px;
        ">大標題</p>`,
        ],
        content: [
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse.",
        ],
      };
    } else if (selectedDiv === 3) {
      addDiv = {
        background_color: `#ffffff`,
        templateId: 3,
        template: "3",
        label: ["#2e5591", "#2e5591"],
        content: [
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse.",
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse.",
        ],
      };
    } else if (selectedDiv === 4) {
      addDiv = {
        background_color: `#ffffff`,
        templateId: 4,
        template: "4",
        img: [Template02],
        images: [Template02],
        label: ["#2e5591"],
        content: [
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse.",
        ],
      };
    } else if (selectedDiv === 5) {
      addDiv = {
        background_color: `#ffffff`,
        templateId: 5,
        template: "5",
        sub_title: [
          `<p style="text-align:center;font-size:
        28px;font-weight: 700;color:#333333;letter-spacing: 0.07px;
        ">大標題</p>`,
        ],
        img: [Template02, Template02],
        images: [Template02, Template02],
        content: [
          "Lorem ipsum dolor sit amet, consectetur ipiscing elit, sed do eiusmod tempor incididunt ut labore.",
          "Lorem ipsum dolor sit amet, consectetur ipiscing elit, sed do eiusmod tempor incididunt ut labore.",
        ],
      };
    } else if (selectedDiv === 6) {
      addDiv = {
        background_color: `#ffffff`,
        templateId: 6,
        template: "6",
        sub_title: [
          `<p style="text-align:center;font-size:
        28px;font-weight: 700;color:#333333;letter-spacing: 0.07px;
        ">大標題</p>`,
        ],
        img: [Template01, Template01, Template01],
        images: [Template01, Template01, Template01],
        content: [
          "Lorem ipsum dolor sit amet, consectetur ipiscing elit, sed do eiusmod tempor incididunt ut labore.",
          "Lorem ipsum dolor sit amet, consectetur ipiscing elit, sed do eiusmod tempor incididunt ut labore.",
          "Lorem ipsum dolor sit amet, consectetur ipiscing elit, sed do eiusmod tempor incididunt ut labore.",
        ],
      };
    }
    if (Array.isArray(allArticleData)) {
      let newData = [...allArticleData];
      newData.push(addDiv);
      setAllArticleData(newData);
    }
  };

  const renderEditors = (data) => {
    return data.map((item, index) => {
      if (item.templateId === 1) {
        return (
          <Template1Editor
            backGroundColor={item?.background_color}
            key={index}
            index={index}
            data={data}
            setData={setAllArticleData}
            templateId={item?.templateId}
            htmlTextArr={item?.content}
            onSave={(updatedMessage) =>
              handleSaveTemplate1(index, updatedMessage)
            }
            handleSaveColor={(color) => {
              handleSaveColor(index, color);
            }}
          />
        );
      } else if (item.templateId === 2) {
        return (
          <Template2Editor
            htmlTitleTextArr={item?.sub_title}
            htmlTextArr={item?.content}
            key={index}
            index={index}
            data={data}
            setData={setAllArticleData}
            templateId={item?.templateId}
            htmlText={item?.content}
            backGroundColor={item?.background_color}
            onSave={(updatedMessageTitle, updatedMessage) =>
              handleSaveTemplate2(index, updatedMessageTitle, updatedMessage)
            }
            handleSaveColor={(color) => {
              handleSaveColor(index, color);
            }}
          />
        );
      } else if (item.templateId === 3) {
        return (
          <Template3Editor
            backGroundColor={item?.background_color}
            key={index}
            index={index}
            data={data}
            setData={setAllArticleData}
            labelColor={item?.label}
            templateId={item?.templateId}
            htmlTextArr={item?.content}
            onSave={(updatedMessage) =>
              handleSaveTemplate1(index, updatedMessage)
            }
            handleSaveColor={(color) => {
              handleSaveColor(index, color);
            }}
            handleSaveLabelColor={(idx, color) => {
              handleSaveLabelColor(index, idx, color);
            }}
          />
        );
      } else if (item.templateId === 4) {
        return (
          <Template4Editor
            backGroundColor={item?.background_color}
            key={index}
            index={index}
            data={data}
            setData={setAllArticleData}
            labelColor={item?.label}
            templateId={item?.templateId}
            imgArr={item?.img}
            htmlTextArr={item?.content}
            onSave={(updatedMessage) =>
              handleSaveTemplate1(index, updatedMessage)
            }
            handleSaveColor={(color) => {
              handleSaveColor(index, color);
            }}
            handleSaveLabelColor={(idx, color) => {
              handleSaveLabelColor(index, idx, color);
            }}
            handleImageChange={(e, setPreviewImage) => {
              handleImageChange(index, e, setPreviewImage);
            }}
          />
        );
      } else if (item.templateId === 5) {
        return (
          <Template5Editor
            backGroundColor={item?.background_color}
            key={index}
            index={index}
            data={data}
            setData={setAllArticleData}
            templateId={item?.templateId}
            imgArr={item?.img}
            htmlTitleTextArr={item?.sub_title}
            htmlTextArr={item?.content}
            onSave={(updatedMessage) =>
              handleSaveTemplate1(index, updatedMessage)
            }
            onSaveTitle={(updatedMessageTitle) =>
              handleSaveTemplate5(index, updatedMessageTitle)
            }
            handleSaveColor={(color) => {
              handleSaveColor(index, color);
            }}
            handleImageChange={(e, setPreviewImage, idx) => {
              handleImageChangeMore(index, e, setPreviewImage, idx);
            }}
          />
        );
      } else if (item.templateId === 6) {
        return (
          <Template6Editor
            backGroundColor={item?.background_color}
            key={index}
            index={index}
            data={data}
            setData={setAllArticleData}
            templateId={item?.templateId}
            imgArr={item?.img}
            htmlTitleTextArr={item?.sub_title}
            htmlTextArr={item?.content}
            onSave={(updatedMessage) =>
              handleSaveTemplate1(index, updatedMessage)
            }
            onSaveTitle={(updatedMessageTitle) =>
              handleSaveTemplate5(index, updatedMessageTitle)
            }
            handleSaveColor={(color) => {
              handleSaveColor(index, color);
            }}
            handleImageChange={(e, setPreviewImage, idx) => {
              handleImageChangeMore(index, e, setPreviewImage, idx);
            }}
          />
        );
      }
      return <div></div>;
    });
  };

  const previewArticleRender = (data) => {
    return data.map((item, index) => {
      if (item.templateId === 1) {
        return (
          <div
            key={index}
            style={{ background: item.background_color }}
            className={styles.previewModalArticleBlock}
          >
            <div className={styles.margin_1EmStyle}>
              <div dangerouslySetInnerHTML={{ __html: item?.content[0] }}></div>
            </div>
          </div>
        );
      } else if (item.templateId === 2) {
        return (
          <div
            key={index}
            style={{ background: item.background_color }}
            className={styles.previewModalArticleBlock}
          >
            <div className={styles.previewTitle}>
              <div className={styles.margin_05EmStyle}>
                <div
                  dangerouslySetInnerHTML={{ __html: item?.sub_title[0] }}
                ></div>
              </div>
            </div>
            <div className={styles.margin_1EmStyle}>
              <div dangerouslySetInnerHTML={{ __html: item?.content[0] }}></div>
            </div>
          </div>
        );
      } else if (item.templateId === 3) {
        return (
          <div
            key={index}
            style={{ background: item.background_color }}
            className={`${styles.previewModalArticleBlock} ${styles.styleChange}`}
          >
            <div className={styles.introductionBlock}>
              {/* <div
                style={{ background: item?.label[0] }}
                className={styles.introduction}
              >
                簡介
              </div> */}
              <div className={styles.margin_1EmStyle}>
                <div
                  dangerouslySetInnerHTML={{ __html: item?.content[0] }}
                ></div>
              </div>
            </div>
            <div className={styles.introductionBlock}>
              {/* <div
                style={{ background: item?.label[1] }}
                className={styles.introduction}
              >
                簡介
              </div> */}
              <div className={styles.margin_1EmStyle}>
                <div
                  dangerouslySetInnerHTML={{ __html: item?.content[1] }}
                ></div>
              </div>
            </div>
          </div>
        );
      } else if (item.templateId === 4) {
        return (
          <div
            key={index}
            style={{ background: item.background_color }}
            className={`${styles.previewModalArticleBlock} ${styles.styleChange}`}
          >
            <div className={`${styles.imgContainer} ${styles.small}`}>
              <img src={item?.img[0]} alt="" />
            </div>
            <div className={styles.introductionImgTextBlock}>
              {/* <div
                style={{ background: item?.label[0] }}
                className={styles.introduction}
              >
                簡介
              </div> */}
              <div className={styles.margin_1EmStyle}>
                <div
                  dangerouslySetInnerHTML={{ __html: item?.content[0] }}
                ></div>
              </div>
            </div>
          </div>
        );
      } else if (item.templateId === 5) {
        return (
          <div
            key={index}
            style={{ background: item.background_color }}
            className={`${styles.previewModalArticleBlock}`}
          >
            <div className={styles.previewTitle}>
              <div className={styles.margin_05EmStyle}>
                <div
                  dangerouslySetInnerHTML={{ __html: item?.sub_title[0] }}
                ></div>
              </div>
            </div>
            <div className={styles.imgTextBlock}>
              <div className={styles.imgText}>
                <div className={`${styles.imgContainer} ${styles.middle}`}>
                  <img src={item?.img[0]} alt="" />
                </div>
                <div className={styles.imgTextRight}>
                  <div className={styles.margin_1EmStyle}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item?.content[0],
                      }}
                    ></div>
                  </div>
                </div>
              </div>
              <div className={styles.imgText}>
                <div className={`${styles.imgContainer} ${styles.middle}`}>
                  <img src={item?.img[1]} alt="" />
                </div>
                <div className={styles.imgTextRight}>
                  <div className={styles.margin_1EmStyle}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item?.content[1],
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      } else if (item.templateId === 6) {
        return (
          <div
            key={index}
            style={{ background: item.background_color }}
            className={`${styles.previewModalArticleBlock}`}
          >
            <div className={styles.previewTitle}>
              <div className={styles.margin_05EmStyle}>
                <div
                  dangerouslySetInnerHTML={{ __html: item?.sub_title[0] }}
                ></div>
              </div>
            </div>
            <div className={styles.imgTextBlock}>
              <div className={styles.imgTextCenter}>
                <div className={`${styles.imgContainer}`}>
                  <img src={item?.img[0]} alt="" />
                </div>
                <div className={styles.margin_1EmStyle}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item?.content[0],
                    }}
                  ></div>
                </div>
              </div>
              <div className={styles.imgTextCenter}>
                <div className={`${styles.imgContainer}`}>
                  <img src={item?.img[1]} alt="" />
                </div>
                <div className={styles.margin_1EmStyle}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item?.content[1],
                    }}
                  ></div>
                </div>
              </div>
              <div className={styles.imgTextCenter}>
                <div className={`${styles.imgContainer}`}>
                  <img src={item?.img[2]} alt="" />
                </div>
                <div className={styles.margin_1EmStyle}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item?.content[2],
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        );
      }
      return <div></div>;
    });
  };

  return (
    <InnerLayout>
      <>
        <Alert
          isWarning={true}
          isOpen={isDeleteItem}
          onClose={() => setIsDeleteItem(false)}
          onConfirm={() => {
            setIsDeleteItem(false);
            dispatch(deleteArticle(deleteItem.id)).then(() => {
              let postObj;
              if (fetchSearchValue !== "") {
                postObj = {
                  page,
                  limit,
                  search: fetchSearchValue,
                };
              } else {
                postObj = {
                  page,
                  limit,
                };
              }
              dispatch(getEnterpriseArticle(postObj));
            });
          }}
          rightButtonText={"確認"}
          leftButtonText="取消"
          mainTitle={`確認刪除案例 ${deleteItem.name} ?`}
        />
        {enterpriseShareLoading && (
          <div className={styles.loadingWrapper}>
            <ClipLoader size={60} />
          </div>
        )}
        <Modal isOpen={isAddTemplateModal} size="xl">
          <div className={styles.addModalTemplate}>
            <div className={styles.addModalTemplateTop}>新增文章區塊</div>
            <div className={styles.addModalTemplateMiddle}>
              <div className={styles.addModalTemplateTextBlock}>
                <p>文字</p>
                <div
                  onClick={() => handleTemplateClick(1)}
                  className={`${styles.addModalTemplateBlock} ${
                    selectedDiv === 1 && styles.selectedDiv
                  }`}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse.
                </div>
                <div
                  onClick={() => handleTemplateClick(2)}
                  className={`${styles.addModalTemplateBlock} ${
                    selectedDiv === 2 && styles.selectedDiv
                  }`}
                >
                  <div className={styles.mainTitle}>大標題</div>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse.
                </div>
                <div
                  onClick={() => handleTemplateClick(3)}
                  className={`${styles.addModalTemplateBlock} ${
                    styles.styleChange
                  } ${selectedDiv === 3 && styles.selectedDiv}`}
                >
                  <div className={styles.introductionBlock}>
                    {/* <div className={styles.introduction}>簡介</div> */}
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ull.
                  </div>
                  <div className={styles.introductionBlock}>
                    {/* <div className={styles.introduction}>簡介</div> */}
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ull.
                  </div>
                </div>
              </div>
              <p>圖片</p>
              <div
                onClick={() => handleTemplateClick(4)}
                className={`${styles.addModalTemplateBlock} ${
                  styles.styleChange
                } ${selectedDiv === 4 && styles.selectedDiv}`}
              >
                <div className={`${styles.imgContainer} ${styles.small}`}>
                  <img src={Template02} alt="" />
                </div>
                <div className={styles.introductionImgTextBlock}>
                  {/* <div className={styles.introduction}>簡介</div> */}
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit
                </div>
              </div>
              <div
                onClick={() => handleTemplateClick(5)}
                className={`${styles.addModalTemplateBlock} ${
                  selectedDiv === 5 && styles.selectedDiv
                }`}
              >
                <div className={styles.mainTitle}>大標題</div>
                <div className={styles.imgTextBlock}>
                  <div className={styles.imgText}>
                    <div className={`${styles.imgContainer} ${styles.middle}`}>
                      <img src={Template02} alt="" />
                    </div>
                    <div className={styles.imgTextRight}>
                      Lorem ipsum dolor sit amet, consectetur ipiscing elit, sed
                      do eiusmod tempor incididunt ut labore.
                    </div>
                  </div>
                  <div className={styles.imgText}>
                    <div className={`${styles.imgContainer} ${styles.middle}`}>
                      <img src={Template02} alt="" />
                    </div>
                    <div className={styles.imgTextRight}>
                      Lorem ipsum dolor sit amet, consectetur ipiscing elit, sed
                      do eiusmod tempor incididunt ut labore.
                    </div>
                  </div>
                </div>
              </div>
              <div
                onClick={() => handleTemplateClick(6)}
                className={`${styles.addModalTemplateBlock} ${
                  selectedDiv === 6 && styles.selectedDiv
                }`}
              >
                <div className={styles.mainTitle}>大標題</div>
                <div className={styles.imgTextBlock}>
                  <div className={styles.imgTextCenter}>
                    <div className={`${styles.imgContainer}`}>
                      <img src={Template01} alt="" />
                    </div>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor.
                  </div>
                  <div className={styles.imgTextCenter}>
                    <div className={`${styles.imgContainer}`}>
                      <img src={Template01} alt="" />
                    </div>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor.
                  </div>
                  <div className={styles.imgTextCenter}>
                    <div className={`${styles.imgContainer}`}>
                      <img src={Template01} alt="" />
                    </div>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor.
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.addModalTemplateBottom}>
              <Button
                type="quaternary"
                title={"取消"}
                onClick={() => {
                  setSelectedDiv(0);
                  setIsAddTemplateModal(false);
                }}
              />
              <Button
                type="primary"
                title={"新增"}
                onClick={() => {
                  if (selectedDiv === 0) {
                    return;
                  }
                  addSelectData(selectedDiv);
                  setSelectedDiv(0);
                  setIsAddTemplateModal(false);
                }}
              />
            </div>
          </div>
        </Modal>
        <Modal isOpen={isPreviewArticleModal} size="xl">
          <div className={styles.previewArticleModal}>
            <div className={styles.previewArticleTopBlock}>
              <div className={styles.previewArticleTopBlockTitle}>預覽</div>
            </div>
            <div className={styles.previewArticleMiddleBlock}>
              {planDate?.startDay !== "" && (
                <div className={styles.previewArticleMiddleBlockTopDate}>
                  {formatDate(planDate)}
                </div>
              )}
              <div className={styles.previewArticleBlockTitle}>
                {articleTitle}
              </div>
              {isPreviewArticleModal && previewArticleRender(allArticleData)}
            </div>
            <div className={styles.previewArticleBottomBlock}>
              <div
                onClick={() => setIsPreviewArticleModal(false)}
                className={styles.previewArticleBottomBlockBtn}
              >
                關閉
              </div>
            </div>
          </div>
        </Modal>
        <div
          className={`${styles.dashboardOuter} ${
            !leftNavigationShow && styles.leftNavigationShow
          }`}
        >
          <div className={styles.header}>企業案例</div>

          <div className={styles.body}>
            {/* step 1 */}
            {!isAddArticleContent && (
              <>
                <div className={styles.bodyTitle}>
                  <div>
                    案例列表
                    {editArticleError && (
                      <span className={styles.bodyTitleSeverError}>
                        伺服器錯誤 請稍後再試
                      </span>
                    )}
                  </div>
                  <div className={styles.searchBlock}>
                    <InputSearch
                      placeholder="搜尋文章名稱"
                      value={searchValue}
                      width={"150px"}
                      height={"24px"}
                      onKeyPress={(e) => {
                        handleEnterApi(e);
                      }}
                      onChange={(e) => setSearchValue(e.target.value)}
                      clickSearchIcon={() => {}}
                      onClear={() => {
                        setSearchValue("");
                        setFetchSearchValue("");
                      }}
                    />
                    <div
                      onClick={() => {
                        setFetchSearchValue(searchValue);
                        setPage(1);
                      }}
                      className={styles.enterpriseSearchBtn}
                    >
                      <span>搜尋</span>
                    </div>
                    <div
                      onClick={() => {
                        dispatch(changeIsEnterprise(true));
                        setIsAddArticleContent(true);
                      }}
                      className={styles.enterprisePurpleBtn}
                    >
                      <IoAddOutline fontSize={20} />
                      <span>新增案例文章</span>
                    </div>
                  </div>
                </div>
                <Alert
                  isWarning={true}
                  isOpen={false}
                  onClose={() => {}}
                  onConfirm={() => {}}
                  rightButtonText={"確認"}
                  leftButtonText="取消"
                  mainTitle={`確認刪除 ?`}
                />
                <Pagination
                  onPageChange={(e) => {
                    pageChangeHandler(e);
                  }}
                  pageCount={page}
                  totalData={enterpriseShareList?.total}
                  totalPages={Math.ceil(enterpriseShareList?.total / limit)}
                  limit={limit}
                />
                {listData?.length === 0 && (
                  <div className={styles.noDataContainer}>目前無案例</div>
                )}
                {listData?.length !== 0 && (
                  <div className={styles.tableContainer}>
                    <table>
                      <thead>
                        <tr>
                          <th style={{ width: "10%" }}>
                            <BsPinAngleFill color="#5856D6" fontSize={20} />
                          </th>
                          <th style={{ width: "30%" }}>文章標題</th>
                          <th style={{ width: "25%" }}>發佈時間</th>
                          <th style={{ width: "20%" }}>是否上架</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {listData?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td style={{ width: "10%" }}>
                                {item.pin_to_top ? (
                                  <BsPinAngleFill
                                    color="#5856D6"
                                    fontSize={20}
                                  />
                                ) : (
                                  <></>
                                )}
                              </td>
                              <td style={{ width: "30%" }}>
                                {truncateTitle(item.title)}
                              </td>
                              <td style={{ width: "25%" }}>
                                <span>{item.publish_at}</span>
                              </td>
                              <td style={{ width: "20%" }}>
                                <AlertSwitch
                                  isChecked={item.display}
                                  onChange={() => {
                                    const newData = listData.map((item, i) =>
                                      i === index
                                        ? { ...item, display: !item.display }
                                        : item
                                    );
                                    setListData(newData);
                                    let apiObj = {
                                      id: item.id,
                                      display: !item.display ? 1 : 0,
                                    };
                                    dispatch(displayArticle(apiObj));
                                  }}
                                />
                              </td>
                              <td className={styles.iconContent}>
                                <div
                                  onClick={() => {
                                    dispatch(
                                      getAloneEnterpriseArticle(item?.id)
                                    )
                                      .then((e) => {
                                        if (e?.payload === undefined) {
                                          return Promise.reject(
                                            "Payload is undefined"
                                          );
                                        }
                                        return e;
                                      })
                                      .then((e) => {
                                        setEditId(e.payload.id);
                                        setArticleTitle(e.payload.title);
                                        setPlanDate(
                                          editDateConvertFormat(
                                            e.payload.publish_at
                                          )
                                        );
                                        setIsPinToTop(e.payload.pin_to_top);
                                        setCoverFiles(
                                          addImgName(e.payload.cover_image)
                                        );
                                        if (e.payload.pin_to_top) {
                                          setLogoFiles(
                                            addImgName(e.payload.pin_image)
                                          );
                                        }
                                        setDesktopFiles(
                                          addImgName(e.payload.web_image)
                                        );
                                        setMobileFiles(
                                          addImgName(e.payload.mobile_image)
                                        );
                                        let upData = e.payload.contents.map(
                                          (item) => {
                                            return {
                                              ...item,
                                              img: item.images.map(
                                                (image) => image.url
                                              ),
                                            };
                                          }
                                        );
                                        setAllArticleData(
                                          addTemplateId(upData)
                                        );

                                        setIsEditArticleContent(true);
                                        dispatch(changeIsEnterprise(true));
                                        setIsAddArticleContent(true);
                                      })
                                      .catch((err) => {
                                        setEditArticleError(true);
                                        dispatch(closeLoading());
                                        console.error("Error:", err);
                                      });
                                  }}
                                  className={styles.btn}
                                >
                                  <MdDriveFileRenameOutline
                                    color="#666666"
                                    fontSize={20}
                                  />
                                </div>
                                <div
                                  onClick={() => {
                                    setDeleteItem({
                                      id: item.id,
                                      name: item.title,
                                    });
                                    setIsDeleteItem(true);
                                  }}
                                  className={styles.btn}
                                >
                                  <MdDeleteOutline
                                    color="#FF766E"
                                    fontSize={20}
                                  />
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
              </>
            )}
            {/* step 2 */}
            {isAddArticleContent && (
              <>
                <div className={styles.addContent}>
                  <span
                    onClick={() => {
                      clearAllOption();
                    }}
                  >
                    <IoIosArrowBack
                      style={{ marginRight: "5px" }}
                      fontSize={20}
                    />
                    {isEditArticleContent ? "編輯文章" : "新增案例文章"}
                  </span>
                  <Button
                    type="tertiary"
                    title={"預覽"}
                    onClick={() => {
                      setIsPreviewArticleModal(true);
                    }}
                  />
                </div>
                <div className={styles.addMiddleContent}>
                  <div className={styles.tableContent}>
                    <div className={styles.block}>
                      <div className={styles.blockTitle}>文章標題</div>
                      <input
                        onChange={(e) => {
                          setArticleTitle(e.target.value);
                        }}
                        placeholder="請輸入"
                        className={styles.blockTextInput}
                        value={articleTitle}
                      ></input>
                    </div>
                    <div className={styles.block}>
                      <div className={styles.blockTitle}>發布時間</div>
                      <Datepicker
                        selectDate={planDate?.startDay}
                        setSelectDate={(e) => {
                          setDateError(false);
                          const formattedDate = e.toLocaleDateString("zh-TW");
                          setPlanDate({
                            ...planDate,
                            startDay: e,
                            date: formattedDate,
                          });
                        }}
                        disableEvents={[
                          {
                            start: `${handleStartDay(new Date())}`,
                            end: `2100-12-31`,
                          },
                        ]}
                        error={dateError}
                      />
                      <div className={styles.selectBlock}>
                        <div className={styles.selectContent}>
                          <Dropdown
                            listItems={dropdownHourList}
                            placeholder={"請選擇時間"}
                            onChange={(e) =>
                              setPlanDate({
                                ...planDate,
                                hour: e.hour,
                              })
                            }
                            value={planDate.hour}
                            caption={"hour"}
                          />
                        </div>
                        <span>時</span>
                        <div className={styles.selectContent}>
                          <Dropdown
                            listItems={dropdownMinList}
                            placeholder={"請選擇時間"}
                            onChange={(e) =>
                              setPlanDate({
                                ...planDate,
                                min: e.min,
                              })
                            }
                            value={planDate.min}
                            caption={"min"}
                          />
                        </div>
                        <span>分</span>
                      </div>
                    </div>
                    <div className={styles.halfBlock}>
                      <div
                        className={`${styles.block} ${styles.halfBlockLeft}`}
                      >
                        <div
                          className={`${styles.blockTitle} ${styles.halfLeft}`}
                        >
                          置頂文章
                        </div>
                        <AlertSwitch
                          isChecked={isPinToTop}
                          onChange={() => setIsPinToTop(!isPinToTop)}
                        />
                      </div>
                      <div
                        className={`${styles.block} ${styles.halfBlockRight}`}
                      >
                        <div
                          className={`${styles.blockTitle} ${
                            styles.halfRight
                          } ${!isPinToTop && styles.disabled}`}
                        >
                          置頂文章企業Logo
                        </div>
                        <div className={styles.importDataModalMidStepButton}>
                          <label>
                            {isPinToTop ? (
                              <input
                                name="uploadLogofile"
                                id="uploadLogofile"
                                type="file"
                                size={16}
                                multiple
                                onChange={(e) => {
                                  getUploadFileLogo(e);
                                }}
                              />
                            ) : (
                              <></>
                            )}
                            <Button
                              disabled={!isPinToTop}
                              icon={<MdOutlineUploadFile />}
                              type="secondary"
                              title="上傳檔案"
                              color="#676767"
                            />
                          </label>
                        </div>
                        <div className={`${styles.filedName}`}>
                          {logoFiles?.name}
                          {errorUploadLogo === "type" && (
                            <span className={styles.labelError}>
                              檔案類型錯誤( png , jpg )
                            </span>
                          )}
                          {errorUploadLogo === "size" && (
                            <span className={styles.labelError}>
                              有檔案超過10MB，無法上傳
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className={styles.block}>
                      <div className={styles.blockTitle}>封面照片</div>
                      <div className={styles.importDataModalMidStepButton}>
                        <label>
                          <input
                            name="uploadFile"
                            id="uploadFile"
                            type="file"
                            size={16}
                            multiple
                            onChange={(e) => getUploadFileCover(e)}
                          />
                          <Button
                            icon={<MdOutlineUploadFile />}
                            type="secondary"
                            title="上傳檔案"
                            color="#676767"
                          />
                        </label>
                      </div>
                      <div
                        className={`${styles.filedName} ${styles.widthOver}`}
                      >
                        {coverFiles?.name}
                        {errorUploadCover === "type" && (
                          <span className={styles.labelError}>
                            檔案類型錯誤( png , jpg )
                          </span>
                        )}
                        {errorUploadCover === "size" && (
                          <span className={styles.labelError}>
                            有檔案超過10MB，無法上傳
                          </span>
                        )}
                      </div>
                    </div>
                    <div className={styles.halfBlock}>
                      <div
                        className={`${styles.block} ${styles.halfBlockLeftNone}`}
                      >
                        <div
                          className={`${styles.blockTitle} ${styles.halfLeftText}`}
                        >
                          首圖
                        </div>
                        <div
                          className={`${styles.blockTitle} ${styles.halfText}`}
                        >
                          網頁尺寸
                        </div>
                        <div className={styles.importDataModalMidStepButton}>
                          <label>
                            <input
                              name="uploadDesktopFile"
                              id="uploadDesktopFile"
                              type="file"
                              size={16}
                              multiple
                              onChange={(e) => {
                                getUploadFileDesktop(e);
                              }}
                            />

                            <Button
                              icon={<MdOutlineUploadFile />}
                              type="secondary"
                              title="上傳檔案"
                              color="#676767"
                            />
                          </label>
                        </div>
                        <div className={styles.filedName}>
                          {desktopFiles?.name}
                          {errorUploadDesktop === "type" && (
                            <span
                              className={`${styles.labelError} ${styles.half}`}
                            >
                              檔案類型錯誤( png , jpg )
                            </span>
                          )}
                          {errorUploadDesktop === "size" && (
                            <span
                              className={`${styles.labelError} ${styles.half}`}
                            >
                              有檔案超過10MB，無法上傳
                            </span>
                          )}
                        </div>
                      </div>
                      <div
                        className={`${styles.block} ${styles.halfBlockRightNone}`}
                      >
                        <div
                          className={`${styles.blockTitle} ${styles.halfRightText}`}
                        >
                          手機尺寸
                        </div>
                        <div className={styles.importDataModalMidStepButton}>
                          <label>
                            <input
                              name="uploadMobileFile"
                              id="uploadMobileFile"
                              type="file"
                              size={16}
                              multiple
                              onChange={(e) => {
                                getUploadFileMobile(e);
                              }}
                            />

                            <Button
                              icon={<MdOutlineUploadFile />}
                              type="secondary"
                              title="上傳檔案"
                              color="#676767"
                            />
                          </label>
                        </div>
                        <div className={styles.filedName}>
                          {mobileFiles?.name}
                          {errorUploadMobile === "type" && (
                            <span className={styles.labelError}>
                              檔案類型錯誤( png , jpg)
                            </span>
                          )}
                          {errorUploadMobile === "size" && (
                            <span className={styles.labelError}>
                              有檔案超過10MB，無法上傳
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className={`${styles.block} ${styles.final}`}>
                      <div className={styles.finalTitleContent}>
                        內文
                        <div
                          onClick={() => {
                            setIsAddTemplateModal(true);
                            setIsAddArticleError(false);
                            setIsAddUpdataArticleImgError(false);
                          }}
                          className={styles.enterpriseWhiteBtn}
                        >
                          <IoAddOutline fontSize={20} />
                          <span>新增文章區塊</span>
                        </div>
                      </div>
                      <div className={styles.templateContent}>
                        {allArticleData.length === 0 && "請輸入"}
                        {renderEditors(allArticleData)}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {isAddArticleContent && (
          <div
            className={`${styles.modalBottomContent} ${
              !leftNavigationShow && styles.leftNavigationShow
            }`}
          >
            <div className={styles.articleError}>
              {saveDataError && <span>儲存失敗 請再試一次</span>}
              {isAddArticleError && <span>文章內容請填完整</span>}
              {isErrorUploadImg && (
                <span>圖片只支援 jpg png 或者 圖片容量大於 9.9 MB</span>
              )}
              {isAddUpdataArticleImgError && <span>請上傳文章內文圖片</span>}
            </div>
            <div className={styles.btnBlock}>
              <Button
                type="quaternary"
                title="取消"
                onClick={() => {
                  clearAllOption();
                }}
              />
              <Button
                type="primary"
                title="完成"
                onClick={() => {
                  if (
                    planDate?.startDay === "" ||
                    !articleTitle ||
                    !coverFiles ||
                    !desktopFiles ||
                    !mobileFiles ||
                    allArticleData.length === 0 ||
                    (isPinToTop && !logoFiles)
                  ) {
                    setIsAddArticleError(true);
                    return;
                  }
                  // 如果是編輯模式
                  if (isEditArticleContent) {
                    let publish_at = formatDateTime(
                      planDate.date,
                      planDate.hour,
                      planDate.min,
                      "00"
                    );
                    let newApiArticle = allArticleData.map((item, index) => {
                      const { img, ...rest } = item;
                      return { ...rest, position: index.toString() };
                    });
                    if (checkImages(newApiArticle)) {
                      setSaveDataError(false);
                      setIsAddUpdataArticleImgError(true);
                      return;
                    }
                    let apiObj;
                    if (isPinToTop && logoFiles) {
                      apiObj = {
                        id: editId,
                        title: articleTitle,
                        cover_image: coverFiles,
                        web_image: desktopFiles,
                        mobile_image: mobileFiles,
                        publish_at,
                        pin_to_top: isPinToTop ? 1 : 0,
                        pin_image: logoFiles,
                        contents: newApiArticle,
                      };
                    } else {
                      apiObj = {
                        id: editId,
                        title: articleTitle,
                        cover_image: coverFiles,
                        web_image: desktopFiles,
                        mobile_image: mobileFiles,
                        publish_at,
                        pin_to_top: 0,
                        contents: newApiArticle,
                      };
                    }
                    dispatch(editArticle(apiObj))
                      .then((e) => {
                        if (e?.payload === undefined) {
                          return Promise.reject("Payload is undefined");
                        }
                      })
                      .then(() => {
                        clearAllOption();
                        setPage(1)
                        dispatch(
                          getEnterpriseArticle({
                            page:1,
                            limit,
                          })
                        );
                      })
                      .catch((err) => {
                        setSaveDataError(true);
                        dispatch(closeLoading());
                        console.error("Error:", err);
                      });
                  } else {
                    let publish_at = formatDateTime(
                      planDate.date,
                      planDate.hour,
                      planDate.min,
                      "00"
                    );
                    let newApiArticle = allArticleData.map((item, index) => {
                      const { img, ...rest } = item;
                      return { ...rest, position: index.toString() };
                    });
                    if (checkImages(newApiArticle)) {
                      setSaveDataError(false);
                      setIsAddUpdataArticleImgError(true);
                      return;
                    } else {
                      let apiObj;
                      if (isPinToTop && logoFiles) {
                        apiObj = {
                          title: articleTitle,
                          cover_image: coverFiles,
                          web_image: desktopFiles,
                          mobile_image: mobileFiles,
                          publish_at,
                          pin_to_top: isPinToTop ? 1 : 0,
                          pin_image: logoFiles,
                          contents: newApiArticle,
                        };
                      } else {
                        apiObj = {
                          title: articleTitle,
                          cover_image: coverFiles,
                          web_image: desktopFiles,
                          mobile_image: mobileFiles,
                          publish_at,
                          pin_to_top: 0,
                          contents: newApiArticle,
                        };
                      }
                      dispatch(addArticle(apiObj)).then(() => {
                        clearAllOption();
                        setPage(1)
                        dispatch(
                          getEnterpriseArticle({
                            page:1,
                            limit,
                          })
                        );
                      });
                    }
                  }
                }}
              />
            </div>
          </div>
        )}
      </>
    </InnerLayout>
  );
};

export default Enterprise;
