// import styles from './style/InputSearch.module.scss';
// import { HiOutlineSearch } from 'react-icons/hi';
// import { BsFillXCircleFill } from 'react-icons/bs';

// const InputSearch = (props) => {
//   const { value, placeholder, onChange, onKeyPress, onClick, onClear, disabled, width } = props;

//   const filter = (e) => {
//     if (e.code === 'Enter') onKeyPress(e);
//   };

//   return (
//     <div className={`${styles.searchSection} `} style={{ width: width ? `${width}` : '160px' }}>
//       <input disabled={disabled} type='text' placeholder={placeholder} onKeyUp={filter} onChange={(e) => onChange(e)} value={value} />
//       {onClear && value && (
//         <div className={styles.searchonClearBox}>
//           <BsFillXCircleFill className={styles.searchClearButton} onClick={onClear} />
//         </div>
//       )}
//       <div>
//         <HiOutlineSearch className={styles.searchButton} onClick={onClick} />
//       </div>
//     </div>
//   );
// };

// export default InputSearch;

import styles from "./style/InputSearch.module.scss";
import { HiOutlineSearch } from "react-icons/hi";
import { BsFillXCircleFill } from "react-icons/bs";

const InputSearch = (props) => {
  const {
    value,
    placeholder,
    onChange,
    onKeyPress,
    clickSearchIcon,
    onClear,
    disabled,
    width,
    height
  } = props;
  return (
    <div
      className={`${styles.searchSection} `}
      style={{
        width: width ? `${width}` : "160px",
        height: height ? `${height}` : "auto",
      }}
    >
      <input
        disabled={disabled}
        type="text"
        placeholder={placeholder}
        onKeyPress={onKeyPress}
        onChange={(e) => onChange(e)}
        value={value}
      />
      {onClear && value && (
        <div className={styles.searchonClearBox}>
          <BsFillXCircleFill
            className={styles.searchClearButton}
            onClick={onClear}
          />
        </div>
      )}
      <div>
        <HiOutlineSearch
          className={styles.searchButton}
          onClick={clickSearchIcon}
        />
      </div>
    </div>
  );
};

export default InputSearch;
