import styles from './style/layout.module.scss';
import InnerLayout from '../Layout/InnerLayout';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import WorkspacePermission from './WorkspacePermission';

import { MdAccountCircle, MdDeleteOutline } from 'react-icons/md';
import { AiOutlinePlus } from 'react-icons/ai';

import Pagination from '../Utilities/PaginationNew';
import Toast from '../Utilities/Toast';
import TextField from '../Utilities/TextField';
import Button from '../Utilities/Button';
import EmptyBox from '../Utilities/EmptyBox';
import Modal from '../Utilities/Modal';
import AlertSwitch from '../Utilities/AlertSwitch';
import Alert from '../Utilities/Alert';
import InputSearch from '../Utilities/InputSearch';
import WorkspaceDetail from './WorkspaceDetail';
import Dropdown from '../Utilities/DropdownDynamic';
import Tag from '../Utilities/Tag';

import Skeleton from 'react-loading-skeleton';

import moment from 'moment';

// NEW LIST
import {
  selectWorkspaceListsSlice,
  removeSuccess,
  getWorkspaceListV2,
  postNewWorkspaceV2,
  deleteWorkspaceV2,
  postWorkspaceActivationV2,
} from '../../store/slice/workspaceList';
import { selectPermissionSettings } from '../../store/slice/permissionSettings';
import LoadingTable from '../Utilities/LoadingTable';

import { selectMenu } from '../../store/slice/manager/menu';
import Tips from '../Utilities/Tips';

const Workspace = () => {
  // DISPATCH
  const dispatch = useDispatch();

  const { menu } = useSelector(selectMenu);

  // INITIAL ARRAY
  const workspace = useSelector(selectWorkspaceListsSlice);
  const { auth } = useSelector(selectPermissionSettings);

  const [activationState, setActivationState] = useState();
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [workspaceInform, setWorkspaceInform] = useState({});
  const [isToastShow, setIsToastShow] = useState(false);
  const [page, setPage] = useState(0);
  const [workspaceDeleteSnackBar, setworkspaceDeleteSnackBar] = useState(false);
  const [workspaceDeleteSuccess, setWorkspaceDeleteSuccess] = useState(false);
  const [workSpaceSave, setWorkSpaceSave] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [fetchSearchValue, setFetchSearchValue] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [workspaceTitle, setWorkspaceTitle] = useState('');
  const [foward, setFoward] = useState({});
  const [isUserDelete, setIsUserDelete] = useState(false);
  const [delIndex, setDelIndex] = useState({});
  const [permissionSettings, setPermissionSettings] = useState({
    modalOpen: false,
  });
  const [userPermissionSuccess, setUserPermissionSuccess] = useState({
    isSuccess: false,
    name: '',
  });
  const limit = 10;

  // 新增workspace的dropdown list
  const [serviceDropdownList, setServiceDropdownList] = useState([]);
  const [dropdownVal, setDropdownVal] = useState('');

  // workspace List Data
  useEffect(() => {
    dispatch(
      getWorkspaceListV2({
        page: fetchSearchValue ? 1 : page + 1,
        limit: limit,
        search: fetchSearchValue,
      })
    );

    if (workspace?.success || workspace?.isDelete) {
      dispatch(removeSuccess());
      setModalShow(false);
    }
    if (fetchSearchValue) setPage(0);
  }, [dispatch, fetchSearchValue, workspace?.isDelete, workspace.workspaceNameLoading, workspace?.success, page]);

  useEffect(() => {
    if (menu) {
      let arr = [];
      menu.forEach((item) => {
        arr.push({
          serviceName: `${item.sys_alias.toUpperCase()}`,
          disabled: auth?.roles?.find(
            (service) => (service.includes('manager') && service.includes(`${item.sys_alias}`)) || service.includes('admin')
          )
            ? false
            : true,
        });
      });

      setServiceDropdownList(arr);
    }
  }, [menu, auth]);

  useEffect(() => {
    if (workspace?.newWorkspaceData?.workspace_name) {
      setPermissionSettings({
        isUserPermission: false,
        modalOpen: true,
        workspace_name: workspace?.newWorkspaceData?.workspace_name,
        manage_service: workspace?.newWorkspaceData?.manage_service?.name,
      });
    }
  }, [workspace?.newWorkspaceData]);

  const handleStartService = (workspace_id, activation_state, workspace_name) => {
    if (!isSnackBarOpen && !isToastShow && !workspaceDeleteSnackBar && (activationState || !activationState)) {
      if (!activation_state) {
        setActivationState(1);
      } else {
        setActivationState(0);
      }
      setWorkspaceInform({
        workspace_id: workspace_id,
        workspace_name: workspace_name,
      });
      setIsSnackBarOpen(true);
    }
  };

  const handleActivation = () => {
    dispatch(
      postWorkspaceActivationV2({
        workspace: workspaceInform.workspace_id,
        activation_state: activationState,
        workspace_name: workspaceInform.workspace_name,
      })
    );
    setIsSnackBarOpen(false);
    setIsToastShow(true);
    setTimeout(() => {
      setIsToastShow(false);
    }, 1500);
    setTimeout(() => {
      setActivationState();
    }, 2000);
  };

  const handleSaveWorkspace = () => {
    let id = menu.find((item) => item.sys_alias.toUpperCase() === dropdownVal).id;

    dispatch(
      postNewWorkspaceV2({
        workspace_name: workspaceTitle,
        service_id: id,
      })
    );
    setIsToastShow(true);
    setWorkSpaceSave(true);
    setTimeout(() => {
      setIsToastShow(false);
      setDropdownVal('');
    }, 1500);
    setTimeout(() => {
      setWorkSpaceSave(false);
      setWorkspaceTitle('');
    }, 2000);
  };

  const handleDelete = (workspace_id, workspace_name) => {
    if (!isSnackBarOpen && !isToastShow && !workspaceDeleteSnackBar) {
      setWorkspaceInform({
        workspace_id: workspace_id,
        workspace_name: workspace_name,
      });
      setworkspaceDeleteSnackBar(true);
    }
  };

  const handleworkspaceDelete = () => {
    dispatch(deleteWorkspaceV2({ workspace: workspaceInform.workspace_id }));
    if (workspace?.workspaceListData?.data?.total % limit === 1 && page !== 0) {
      setPage(page - 1);
    }
    setworkspaceDeleteSnackBar(false);
    setIsToastShow(true);
    setWorkspaceDeleteSuccess(true);
    setTimeout(() => {
      setIsToastShow(false);
    }, 1500);
    setTimeout(() => {
      setWorkspaceDeleteSuccess(false);
    }, 2000);
  };

  const handleforward = (data) => {
    setFoward({
      isFoward: true,
      fowardId: data.id,
      fowardName: data.workspace_name,
      services_item: data.manage_service,
    });
  };

  const restart = () => {
    setFetchSearchValue('');
    let parameters = {
      limit: limit,
      page: page + 1,
      search: '',
    };
    dispatch(getWorkspaceListV2(parameters));
  };

  return (
    <>
      <WorkspaceDetail
        isOpen={foward.isFoward}
        workspaceId={foward.fowardId}
        setFoward={setFoward}
        foward={foward}
        setIsUserDelete={setIsUserDelete}
        setIsToastShow={setIsToastShow}
        delIndex={delIndex}
        setDelIndex={setDelIndex}
        setUserPermissionSuccess={setUserPermissionSuccess}
      />

      <InnerLayout>
        <Toast
          show={isToastShow}
          title={
            activationState === 1
              ? '啟用成功'
              : workspaceDeleteSuccess
              ? '刪除成功'
              : workSpaceSave
              ? '新增成功'
              : activationState === 0
              ? '停用成功'
              : userPermissionSuccess.isSuccess
              ? '已成功套用此權限設定'
              : '刪除使用者'
          }
          subtitle={
            activationState === 0 || activationState === 1 || workspaceDeleteSuccess
              ? workspaceInform.workspace_name
              : isUserDelete
              ? delIndex.user_name
              : userPermissionSuccess.isSuccess
              ? userPermissionSuccess.name
              : workspaceTitle
          }
        />

        <div className={styles.dashboard}>
          <div className={styles.dashboardWorkspace}>
            <div className={styles.dashboardTitle}>Workspace</div>
            <div className={styles.dashboardListContainer}>
              <div className={styles.dashboardListTitle}>Workspace 列表</div>
            </div>

            <div className={styles.searchBar}>
              <InputSearch
                disabled={workspace?.tableLoading || (fetchSearchValue === '' && workspace?.workspaceListData?.data?.rows?.length === 0)}
                placeholder='搜尋 Workspace 名稱'
                value={searchTerm}
                width={'320px'}
                onKeyPress={(e) => (e.code === 'Enter' || e.charCode === 13) && !workspace?.tableLoading && setFetchSearchValue(searchTerm)}
                onChange={(e) => setSearchTerm(e.target.value)}
                clickSearchIcon={() => {
                  if (!workspace?.tableLoading) {
                    setFetchSearchValue(searchTerm);
                    setPage(1);
                  }
                }}
                onClear={() => {
                  restart();
                  setSearchTerm('');
                }}
              />
              {auth?.roles.find((item) => item === 'workspace.admin' || (item.includes('workspace') && item.includes('manager'))) &&
                workspace?.workspaceListData?.data?.rows?.length > 0 && (
                  <div>
                    <Button type='tertiary' title={'新增workspace'} onClick={() => setModalShow(true)} icon={<AiOutlinePlus />} />
                  </div>
                )}
            </div>

            <Modal isOpen={modalShow} height={'280px'} size='sm'>
              <div className={styles.mdRelative}>
                <div className={styles.ModalTitle}>新增Workspace</div>

                <div className={styles.ModalName}>Workspace名稱</div>
                {workspace?.tableLoading ? (
                  <Skeleton width={'100%'} height={43} />
                ) : (
                  <TextField
                    width={'100%'}
                    placeholder={'請輸入'}
                    onChange={(e) => setWorkspaceTitle(e)}
                    value={workspaceTitle}
                    error={workspaceTitle.length > 10}
                  />
                )}
                {workspaceTitle.length > 10 && <div className={styles.errMsg}>長度不可超過十字</div>}

                <div className={styles.ModalName} style={{ marginTop: '16px' }}>
                  服務項目
                </div>
                {workspace?.tableLoading ? (
                  <Skeleton width={'100%'} height={43} />
                ) : (
                  <Dropdown
                    listItems={serviceDropdownList}
                    placeholder={'請選擇'}
                    onChange={(e) => setDropdownVal(e.serviceName)}
                    value={dropdownVal}
                    caption={'serviceName'}
                  />
                )}
              </div>

              <div className={styles.btnContainer}>
                <Button
                  title={'取消'}
                  type={'tertiary'}
                  onClick={() => {
                    setModalShow(false);
                    setWorkspaceTitle('');
                    setDropdownVal('');
                  }}
                  disabled={workspace?.tableLoading}
                />
                <div className={styles.btnflex}></div>
                <Button
                  title={'儲存'}
                  type={'primary'}
                  onClick={handleSaveWorkspace}
                  disabled={workspaceTitle.length > 10 || workspaceTitle.length === 0 || !dropdownVal}
                />
              </div>
            </Modal>

            <Alert
              isOpen={workspaceDeleteSnackBar || isSnackBarOpen}
              onClose={() => {
                if (isSnackBarOpen) {
                  setIsSnackBarOpen(false);
                } else {
                  setworkspaceDeleteSnackBar(false);
                }
              }}
              onConfirm={isSnackBarOpen ? handleActivation : handleworkspaceDelete}
              mainTitle={
                isSnackBarOpen && activationState === 1
                  ? `啟用${workspaceInform.workspace_name}？`
                  : isSnackBarOpen && activationState === 0
                  ? `停用${workspaceInform.workspace_name}？`
                  : `刪除${workspaceInform.workspace_name}?`
              }
              leftButtonText={'取消'}
              rightButtonText={isSnackBarOpen ? '確認' : '刪除'}
            />
            <div className={styles.tableContainer}>
              {workspace?.tableLoading ? (
                <table>
                  <thead>
                    <tr>
                      <th>Workspace名稱</th>
                      <th>建立時間</th>
                      <th>人數</th>
                      <th>服務項目</th>
                      {auth?.roles.find((item) => item === 'workspace.admin' || (item.includes('workspace') && item.includes('manager'))) && (
                        <th>啟用狀況</th>
                      )}
                      <th>權限管理</th>
                      {auth?.roles.find((item) => item === 'workspace.admin' || (item.includes('workspace') && item.includes('manager'))) && (
                        <th></th>
                      )}
                    </tr>
                  </thead>

                  <LoadingTable row={10} column={5} />
                </table>
              ) : (
                <table>
                  <thead>
                    <tr>
                      <th>Workspace名稱</th>
                      <th>建立時間</th>
                      <th>人數</th>
                      <th>服務項目</th>
                      {auth?.roles.find((item) => item === 'workspace.admin' || (item.includes('workspace') && item.includes('manager'))) && (
                        <th>啟用狀況</th>
                      )}
                      <th>權限管理</th>
                      {auth?.roles.find((item) => item === 'workspace.admin' || (item.includes('workspace') && item.includes('manager'))) && (
                        <th></th>
                      )}
                    </tr>
                  </thead>
                  {workspace?.workspaceListData?.data?.rows?.length > 0 ? (
                    <tbody>
                      {workspace?.workspaceListData?.data?.rows?.map((data, index) => (
                        <React.Fragment key={index}>
                          <tr>
                            <td className={styles.tableTd}>{data?.workspace_name}</td>
                            <td className={styles.tableTd}>{moment(data?.created_at).format('YYYY-MM-DD')}</td>
                            <td className={styles.tableTd}>{data?.workspace_users_count}</td>
                            {/* todo */}
                            <td className={styles.tableTd}>
                              {data?.manage_service ? <Tag title={data?.manage_service?.alias} isDisplayOnly={true} /> : <>-</>}
                            </td>

                            {auth?.roles.find((item) => item === 'workspace.admin' || (item.includes('workspace') && item.includes('manager'))) && (
                              <td className={styles.tableTd}>
                                <AlertSwitch
                                  isChecked={data?.activation_state}
                                  onChange={() => handleStartService(data?.id, data?.activation_state, data?.workspace_name)}
                                />
                              </td>
                            )}

                            <td className={styles.tableTd}>
                              <Button type='tertiary' title={'前往'} onClick={() => handleforward(data)} />
                            </td>

                            {auth?.roles.find((item) => item === 'workspace.admin' || (item.includes('workspace') && item.includes('manager'))) && (
                              <td className={styles.tableTd}>
                                {!data.is_representative ? (
                                  <Button type='pure' onClick={() => handleDelete(data?.id, data?.workspace_name)} icon={<MdDeleteOutline />} />
                                ) : (
                                  <Tips leftDiff={-8} topDiff={-5} detail='負責人Workspace，無法刪除。'>
                                    <Button type={'pure'} icon={<MdAccountCircle />} />
                                  </Tips>
                                )}
                              </td>
                            )}
                          </tr>
                        </React.Fragment>
                      ))}
                    </tbody>
                  ) : workspace?.workspaceListData?.data?.rows?.length === 0 && searchTerm.length === 0 ? (
                    <tbody>
                      <tr>
                        <td colSpan='5'>
                          <EmptyBox caption={'尚未新增workspace'} title={'新增workspace'} onClick={() => setModalShow(true)} />
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      <tr style={{ border: '1px solid red' }}>
                        <td colSpan='5'>
                          <div className={styles.noResult}>
                            <h4>搜尋無結果，請更換搜尋關鍵字</h4>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              )}
              <Pagination
                onPageChange={(e) => {
                  setPage(e.selected);
                }}
                pageCount={page + 1}
                totalData={workspace?.workspaceListData?.data?.total}
                totalPages={Math.ceil(workspace?.workspaceListData?.data?.total / limit)}
                limit={limit}
              />
            </div>
          </div>
        </div>

        <WorkspacePermission
          permissionSettings={permissionSettings}
          setPermissionSettings={setPermissionSettings}
          isUser={permissionSettings.isUserPermission}
          workspaceId={workspace?.newWorkspaceData?.id}
          settingIndex={null} //非user身份
          setFoward={setFoward}
        />
      </InnerLayout>
    </>
  );
};

export default Workspace;
