import React, { useState, useEffect, useRef } from "react";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import styles from "./style/Enterprise.module.scss";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import {
  IoChevronDownOutline,
  IoChevronUp,
  IoColorFillOutline,
} from "react-icons/io5";
import { MdDeleteOutline } from "react-icons/md";
import Alert from "../Utilities/Alert";
import { ChromePicker } from "react-color";
import Button from "../Utilities/Button";
import { MdOutlineUploadFile } from "react-icons/md";
import Template01 from "../../assets/template01.jpg";
import Template02 from "../../assets/template02.jpg";


// 處理各個區塊上下移動跟刪除的邏輯
const handleMoveItemDown = (index, data, setData) => {
  const newData = [...data];
  const itemToMove = newData[index];
  newData.splice(index, 1);
  newData.splice(index + 1, 0, itemToMove);
  setData(newData);
};

const handleMoveItemUp = (index, data, setData) => {
  if (index === 0) return;
  const newData = [...data];
  const itemToMove = newData[index];
  newData.splice(index, 1);
  newData.splice(index - 1, 0, itemToMove);
  setData(newData);
};

const handleDeleteItem = (index, data, setData) => {
  const newData = [...data];
  newData.splice(index, 1);

  setData(newData);
};

const ColorPicker = ({ nowChangeColor, setNowChangeColor }) => {
  const handleChange = (newColor) => {
    setNowChangeColor(newColor.hex);
  };

  return (
    <div className={styles.colorBlock}>
      <ChromePicker
        disableAlpha={true}
        color={nowChangeColor}
        onChange={handleChange}
      />
    </div>
  );
};

// const ColorLabelPicker = ({ nowChangeColor, setNowChangeColor, idx, data }) => {
//   const handleChange = (newColor) => {
//     let newData = [...data];
//     newData[idx] = newColor.hex;
//     setNowChangeColor(newData);
//   };

//   return (
//     <div className={styles.colorLabelBlock}>
//       <ChromePicker
//         disableAlpha={true}
//         color={nowChangeColor}
//         onChange={handleChange}
//       />
//     </div>
//   );
// };

const addFontSizeIfNotExist = (inputHtml) => {
  return inputHtml.replace(/<p(.*?)>(.*?)<\/p>/g, (match, p1, p2) => {
    if (!p1.includes("font-size") && !p1.includes("font")) {
      return `<p${p1}><span style="color: rgb(51,51,51);font-size: 28px;">${p2}</span></p>`;
    }
    return match;
  });
};

export const Template1Editor = ({
  htmlTextArr,
  onSave,
  index,
  data,
  setData,
  backGroundColor,
  handleSaveColor,
}) => {
  const [editorStates, setEditorStates] = useState(
    htmlTextArr.map(() => EditorState.createEmpty())
  );
  const [messages, setMessages] = useState(htmlTextArr);
  const [isDeleteItem, setIsDeleteItem] = useState(false);
  const [hoverBtn, setHoverBtn] = useState(false);
  const [nowChangeColor, setNowChangeColor] = useState("#ffffff");
  const [colorBtn, setColorBtn] = useState(false);

  const [toolbarHidden, setToolbarHidden] = useState(
    htmlTextArr.map(() => true)
  );

  const editorRef = useRef(null);

  useEffect(() => {
    setNowChangeColor(backGroundColor);
  }, [backGroundColor]);

  useEffect(() => {
    if (htmlTextArr && htmlTextArr.length > 0) {
      const newEditorStates = htmlTextArr.map((htmlText) => {
        const contentBlock = htmlToDraft(htmlText);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          return EditorState.createWithContent(contentState);
        } else {
          return EditorState.createWithContent();
        }
      });
      setEditorStates(newEditorStates);
      setMessages(htmlTextArr);
    }
  }, [htmlTextArr]);

  const onEditorStateChange = (newEditorState, idx) => {
    if (!newEditorState) return;
    const content = newEditorState.getCurrentContent();
    const newEditorStates = [...editorStates];
    const newMessages = [...messages];

    newEditorStates[idx] = newEditorState;
    newMessages[idx] = draftToHtml(convertToRaw(content));

    setEditorStates(newEditorStates);
    setMessages(newMessages);
  };

  const handleFocus = (idx) => {
    setToolbarHidden((prevState) => {
      const newState = [...prevState];
      newState[idx] = false;
      return newState;
    });
  };

  const handleBlur = () => {
    setToolbarHidden((prevState) => {
      const newState = prevState.map(() => true);
      return newState;
    });
    if (onSave) {
      onSave(messages);
    }
  };

  return (
    <>
      <Alert
        isWarning={true}
        isOpen={isDeleteItem}
        onClose={() => setIsDeleteItem(false)}
        onConfirm={() => handleDeleteItem(index, data, setData)}
        rightButtonText={"確認"}
        leftButtonText="取消"
        mainTitle={`確認刪除此文章區塊 ?`}
      />

      <div
        onMouseEnter={() => {
          setHoverBtn(true);
        }}
        onMouseLeave={() => {
          setHoverBtn(false);
          if (colorBtn) {
            handleSaveColor(nowChangeColor);
            setColorBtn(false);
          }
        }}
        className={styles.editorBlock}
        style={{ background: nowChangeColor }}
      >
        {htmlTextArr.map((_, idx) => (
          <div key={idx}>
            <Editor
              toolbarHidden={toolbarHidden[idx]}
              ref={editorRef}
              toolbar={{
                options: ["inline", "colorPicker", "textAlign"],
                inline: {
                  options: ["bold", "italic", "underline"],
                },
              }}
              onFocus={() => handleFocus(idx)}
              onBlur={() => handleBlur()}
              editorState={editorStates[idx]}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              onEditorStateChange={(newEditorState) =>
                onEditorStateChange(newEditorState, idx)
              }
              readOnly={false}
            />
          </div>
        ))}
        {hoverBtn && (
          <div className={styles.editorBlockOptionBtn}>
            <div className={styles.optionBtn}>
              <div
                onClick={() => handleMoveItemDown(index, data, setData)}
                className={styles.optionBtnIcon}
              >
                <IoChevronDownOutline fontSize={20} />
              </div>
            </div>
            <div className={styles.optionBtn}>
              <div
                onClick={() => handleMoveItemUp(index, data, setData)}
                className={styles.optionBtnIcon}
              >
                <IoChevronUp fontSize={20} />
              </div>
            </div>
            <div className={styles.optionBtn}>
              <div className={styles.optionBtnIcon}>
                <IoColorFillOutline
                  onClick={() => setColorBtn(true)}
                  fontSize={20}
                />
              </div>
            </div>
            <div className={styles.optionBtn}>
              <div
                onClick={() => setIsDeleteItem(true)}
                className={styles.optionBtnIcon}
              >
                <MdDeleteOutline color="#FF766E" fontSize={20} />
              </div>
            </div>
          </div>
        )}
        {colorBtn && hoverBtn && (
          <ColorPicker
            nowChangeColor={nowChangeColor}
            setNowChangeColor={setNowChangeColor}
          />
        )}
      </div>
    </>
  );
};

export const Template2Editor = ({
  templateId,
  htmlTextArr,
  htmlTitleTextArr,
  onSave,
  index,
  data,
  setData,
  backGroundColor,
  handleSaveColor,
}) => {
  const [editorTitleStates, setEditorTitleStates] = useState(
    htmlTitleTextArr.map(() => EditorState.createEmpty())
  );
  
  const [editorStates, setEditorStates] = useState(
    htmlTextArr.map(() => EditorState.createEmpty())
  );

  const [messagesTitle, setMessagesTitle] = useState(htmlTitleTextArr);
  const [messages, setMessages] = useState(htmlTextArr);

  const [hoverBtn, setHoverBtn] = useState(false);
  const [isDeleteItem, setIsDeleteItem] = useState(false);
  const [nowChangeColor, setNowChangeColor] = useState("#ffffff");
  const [colorBtn, setColorBtn] = useState(false);

  const [toolbarTitleHidden, setToolbarTitleHidden] = useState(true);

  const [toolbarHidden, setToolbarHidden] = useState(true);

  const editorTitleRef = useRef(null);
  const editorRef = useRef(null);

  useEffect(() => {
    setNowChangeColor(backGroundColor);
  }, [backGroundColor]);

  useEffect(() => {
    const updatedTitleStates = htmlTitleTextArr.map((htmlMessage) => {
      const contentBlock = htmlToDraft(htmlMessage);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        return EditorState.createWithContent(contentState);
      } else {
        return EditorState.createWithContent();
      }
    });
    setEditorTitleStates(updatedTitleStates);
    setMessagesTitle(htmlTitleTextArr);
  }, [htmlTitleTextArr]);

  useEffect(() => {
    const updatedContentStates = htmlTextArr.map((htmlMessage) => {
      const contentBlock = htmlToDraft(htmlMessage);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        return EditorState.createWithContent(contentState);
      } else {
        return EditorState.createWithContent();
      }
    });
    setEditorStates(updatedContentStates);
    setMessages(htmlTextArr);
  }, [htmlTextArr]);

  const onEditorStateTitleChange = (newEditorState, idx) => {
    if (!newEditorState) return;
    const content = newEditorState.getCurrentContent();
    const updatedEditorStates = [...editorTitleStates];
    const updatedMessagesTitle = [...messagesTitle];

    updatedEditorStates[idx] = newEditorState;
    updatedMessagesTitle[idx] = draftToHtml(convertToRaw(content));

    setEditorTitleStates(updatedEditorStates);
    setMessagesTitle(updatedMessagesTitle);
  };

  const onEditorStateChange = (newEditorState, idx) => {
    if (!newEditorState) return;
    const content = newEditorState.getCurrentContent();
    const updatedEditorStates = [...editorStates];
    const updatedMessages = [...messages];

    updatedEditorStates[idx] = newEditorState;
    updatedMessages[idx] = draftToHtml(convertToRaw(content));

    setEditorStates(updatedEditorStates);
    setMessages(updatedMessages);
  };

  const handleTitleFocus = () => {
    setToolbarTitleHidden(false);
  };

  const handleFocus = () => {
    setToolbarHidden(false);
  };

  const handleTitleBlur = () => {
    setToolbarTitleHidden(true);
    if (onSave) {
      if (
        messagesTitle[0] === '<p style="text-align:center;"></p>\n' ||
        messagesTitle[0] === "<p></p>"
      ) {
        messagesTitle[0] = `<p style="text-align:center;"><span style="color: rgb(51,51,51);font-size: 28px;"></span></p>\n`;
      } else {
        messagesTitle[0] = addFontSizeIfNotExist(messagesTitle[0]);
      }

      onSave(messagesTitle, messages);
    }
  };

  const handleBlur = () => {
    setToolbarHidden(true);
    if (onSave) {
      onSave(messagesTitle, messages);
    }
  };

  return (
    <>
      <Alert
        isWarning={true}
        isOpen={isDeleteItem}
        onClose={() => setIsDeleteItem(false)}
        onConfirm={() => handleDeleteItem(index, data, setData)}
        rightButtonText={"確認"}
        leftButtonText="取消"
        mainTitle={`確認刪除此文章區塊 ?`}
      />
      <div
        onMouseEnter={() => setHoverBtn(true)}
        onMouseLeave={() => {
          setHoverBtn(false);
          if (colorBtn) {
            handleSaveColor(nowChangeColor);
            setColorBtn(false);
          }
        }}
        className={styles.editorBlock}
        style={{ background: nowChangeColor }}
      >
        {htmlTitleTextArr.map((_, idx) => (
          <div key={idx}>
            {templateId === 2 && (
              <Editor
                toolbarHidden={toolbarTitleHidden}
                ref={editorTitleRef}
                editorState={editorTitleStates[idx]}
                onEditorStateChange={(newEditorState) =>
                  onEditorStateTitleChange(newEditorState, idx)
                }
                onFocus={handleTitleFocus}
                onBlur={handleTitleBlur}
                readOnly={false}
                toolbar={{
                  options: ["fontSize", "textAlign"],
                  fontSize: {
                    options: ["24","28", "32"],
                  },
                  inline: {
                    options: [],
                  },
                }}
              />
            )}
          </div>
        ))}
        {htmlTextArr.map((_, idx) => (
          <div key={idx}>
            <Editor
              toolbarHidden={toolbarHidden}
              ref={editorRef}
              editorState={editorStates[idx]}
              onEditorStateChange={(newEditorState) =>
                onEditorStateChange(newEditorState, idx)
              }
              onFocus={handleFocus}
              onBlur={handleBlur}
              toolbar={{
                options: ["inline", "colorPicker", "textAlign"],
                inline: {
                  options: ["bold", "italic", "underline"],
                },
              }}
              readOnly={false}
            />
          </div>
        ))}

        {hoverBtn && (
          <>
            <div className={styles.editorBlockOptionBtn}>
              <div className={styles.optionBtn}>
                <div
                  onClick={() => handleMoveItemDown(index, data, setData)}
                  className={styles.optionBtnIcon}
                >
                  <IoChevronDownOutline fontSize={20} />
                </div>
              </div>
              <div className={styles.optionBtn}>
                <div
                  onClick={() => handleMoveItemUp(index, data, setData)}
                  className={styles.optionBtnIcon}
                >
                  <IoChevronUp fontSize={20} />
                </div>
              </div>
              <div className={styles.optionBtn}>
                <div className={styles.optionBtnIcon}>
                  <IoColorFillOutline
                    onClick={() => setColorBtn(true)}
                    fontSize={20}
                  />
                </div>
              </div>
              <div className={styles.optionBtn}>
                <div
                  onClick={() => setIsDeleteItem(true)}
                  className={styles.optionBtnIcon}
                >
                  <MdDeleteOutline color="#FF766E" fontSize={20} />
                </div>
              </div>
            </div>
          </>
        )}

        {colorBtn && hoverBtn && (
          <ColorPicker
            nowChangeColor={nowChangeColor}
            setNowChangeColor={setNowChangeColor}
          />
        )}
      </div>
    </>
  );
};

export const Template3Editor = ({
  htmlTextArr,
  onSave,
  index,
  data,
  setData,
  backGroundColor,
  handleSaveColor,
  // handleSaveLabelColor,
  labelColor,
}) => {
  const [editorStates, setEditorStates] = useState(
    htmlTextArr.map(() => EditorState.createEmpty())
  );
  const [messages, setMessages] = useState(htmlTextArr);
  const [isDeleteItem, setIsDeleteItem] = useState(false);
  const [hoverBtn, setHoverBtn] = useState(false);
  const [nowChangeColor, setNowChangeColor] = useState("#ffffff");

  // const [setNowLabelChangeColor] = useState([
  //   "#ffffff",
  //   "#ffffff",
  // ]);
  const [colorBtn, setColorBtn] = useState(false);

  const [option, setOption] = useState([false, false]);

  // const [setLabelColorBtn] = useState(false);

  const [toolbarHidden, setToolbarHidden] = useState(
    htmlTextArr.map(() => true)
  );

  const editorRef = useRef(null);

  useEffect(() => {
    setNowChangeColor(backGroundColor);
  }, [backGroundColor]);

  // useEffect(() => {
  //   setNowLabelChangeColor([labelColor[0], labelColor[1]]);
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [labelColor]);

  useEffect(() => {
    if (htmlTextArr && htmlTextArr.length > 0) {
      const newEditorStates = htmlTextArr.map((htmlText) => {
        const contentBlock = htmlToDraft(htmlText);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          return EditorState.createWithContent(contentState);
        } else {
          return EditorState.createWithContent();
        }
      });
      setEditorStates(newEditorStates);
      setMessages(htmlTextArr);
    }
  }, [htmlTextArr]);

  const onEditorStateChange = (newEditorState, idx) => {
    if (!newEditorState) return;
    const content = newEditorState.getCurrentContent();
    const newEditorStates = [...editorStates];
    const newMessages = [...messages];

    newEditorStates[idx] = newEditorState;
    newMessages[idx] = draftToHtml(convertToRaw(content));

    setEditorStates(newEditorStates);
    setMessages(newMessages);
  };

  const handleFocus = (idx) => {
    setToolbarHidden((prevState) => {
      const newState = [...prevState];
      newState[idx] = false;
      return newState;
    });
  };

  const handleBlur = () => {
    setToolbarHidden((prevState) => {
      const newState = prevState.map(() => true);
      return newState;
    });
    if (onSave) {
      onSave(messages);
    }
  };

  // const handleClick = (idx) => {
  //   const newOptions = [...option];
  //   newOptions[idx] = true;
  //   setOption(newOptions);
  // };

  return (
    <>
      <Alert
        isWarning={true}
        isOpen={isDeleteItem}
        onClose={() => setIsDeleteItem(false)}
        onConfirm={() => handleDeleteItem(index, data, setData)}
        rightButtonText={"確認"}
        leftButtonText="取消"
        mainTitle={`確認刪除此文章區塊 ?`}
      />
      <div
        onMouseEnter={() => {
          setHoverBtn(true);
        }}
        onMouseLeave={() => {
          setHoverBtn(false);
          if (colorBtn) {
            handleSaveColor(nowChangeColor);
            setColorBtn(false);
          }
        }}
        className={styles.editorBlock}
        style={{ background: nowChangeColor }}
      >
        <div className={styles.editorBlockFlex}>
          {htmlTextArr.map((_, idx) => (
            <div
              className={styles.width_47}
              key={idx}
              onMouseLeave={() => {
                const newOptions = [...option];
                newOptions[idx] = false;
                setOption(newOptions);
                // setLabelColorBtn(false);
              }}
            >
              {/* <div
                style={{
                  background:
                    Array.isArray(nowLabelChangeColor) &&
                    nowLabelChangeColor[idx],
                }}
                className={styles.introduction}
                onMouseEnter={() => {
                  handleClick(idx);
                }}
              >
                簡介
              </div> */}
              {/* <div
                onMouseLeave={() => {
                  handleSaveLabelColor(idx, nowLabelChangeColor[idx]);
                }}
              >
                {option[idx] && (
                  <div className={styles.editorLabelOptionBtn}>
                    <div className={styles.optionBtnIcon}>
                      <IoColorFillOutline
                        onClick={() => {
                          setLabelColorBtn(true);
                        }}
                        fontSize={20}
                      />
                    </div>
                    {labelColorBtn && (
                      <ColorLabelPicker
                        data={nowLabelChangeColor}
                        idx={idx}
                        nowChangeColor={nowLabelChangeColor[idx]}
                        setNowChangeColor={setNowLabelChangeColor}
                      />
                    )}
                  </div>
                )}
              </div> */}
              <Editor
                toolbarHidden={toolbarHidden[idx]}
                ref={editorRef}
                toolbar={{
                  options: ["inline", "colorPicker", "textAlign"],
                  inline: {
                    options: ["bold", "italic", "underline"],
                  },
                }}
                onFocus={() => handleFocus(idx)}
                onBlur={() => handleBlur()}
                editorState={editorStates[idx]}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                onEditorStateChange={(newEditorState) =>
                  onEditorStateChange(newEditorState, idx)
                }
                readOnly={false}
              />
            </div>
          ))}
        </div>
        {hoverBtn && (
          <div className={styles.editorBlockOptionBtn}>
            <div className={styles.optionBtn}>
              <div
                onClick={() => handleMoveItemDown(index, data, setData)}
                className={styles.optionBtnIcon}
              >
                <IoChevronDownOutline fontSize={20} />
              </div>
            </div>
            <div className={styles.optionBtn}>
              <div
                onClick={() => handleMoveItemUp(index, data, setData)}
                className={styles.optionBtnIcon}
              >
                <IoChevronUp fontSize={20} />
              </div>
            </div>
            <div className={styles.optionBtn}>
              <div className={styles.optionBtnIcon}>
                <IoColorFillOutline
                  onClick={() => setColorBtn(true)}
                  fontSize={20}
                />
              </div>
            </div>
            <div className={styles.optionBtn}>
              <div
                onClick={() => setIsDeleteItem(true)}
                className={styles.optionBtnIcon}
              >
                <MdDeleteOutline color="#FF766E" fontSize={20} />
              </div>
            </div>
          </div>
        )}
        {colorBtn && hoverBtn && (
          <ColorPicker
            nowChangeColor={nowChangeColor}
            setNowChangeColor={setNowChangeColor}
          />
        )}
      </div>
    </>
  );
};

export const Template4Editor = ({
  htmlTextArr,
  onSave,
  index,
  data,
  setData,
  backGroundColor,
  handleSaveColor,
  handleSaveLabelColor,
  labelColor,
  handleImageChange,
  imgArr,
}) => {
  const [editorStates, setEditorStates] = useState(
    htmlTextArr.map(() => EditorState.createEmpty())
  );
  const [messages, setMessages] = useState(htmlTextArr);
  const [isDeleteItem, setIsDeleteItem] = useState(false);
  const [hoverBtn, setHoverBtn] = useState(false);
  const [nowChangeColor, setNowChangeColor] = useState("#ffffff");

  const [previewImage, setPreviewImage] = useState([Template02]);

  // const [, setNowLabelChangeColor] = useState([
  //   "#ffffff",
  //   "#ffffff",
  // ]);
  const [colorBtn, setColorBtn] = useState(false);

  // const [, setLabelColorBtn] = useState(false);

  const [toolbarHidden, setToolbarHidden] = useState(
    htmlTextArr.map(() => true)
  );

  const [option, setOption] = useState([false]);

  const [optionImg, setOptionImg] = useState([false]);

  const editorRef = useRef(null);

  useEffect(() => {
    setNowChangeColor(backGroundColor);
  }, [backGroundColor]);

  // useEffect(() => {
  //   setNowLabelChangeColor([labelColor[0], labelColor[1]]);
  // }, [labelColor]);

  useEffect(() => {
    setPreviewImage([imgArr[0]]);
  }, [imgArr]);

  useEffect(() => {
    if (htmlTextArr && htmlTextArr.length > 0) {
      const newEditorStates = htmlTextArr.map((htmlText) => {
        const contentBlock = htmlToDraft(htmlText);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          return EditorState.createWithContent(contentState);
        } else {
          return EditorState.createWithContent();
        }
      });
      setEditorStates(newEditorStates);
      setMessages(htmlTextArr);
    }
  }, [htmlTextArr]);

  const onEditorStateChange = (newEditorState, idx) => {
    if (!newEditorState) return;
    const content = newEditorState.getCurrentContent();
    const newEditorStates = [...editorStates];
    const newMessages = [...messages];

    newEditorStates[idx] = newEditorState;
    newMessages[idx] = draftToHtml(convertToRaw(content));

    setEditorStates(newEditorStates);
    setMessages(newMessages);
  };

  const handleFocus = (idx) => {
    setToolbarHidden((prevState) => {
      const newState = [...prevState];
      newState[idx] = false;
      return newState;
    });
  };

  const handleBlur = () => {
    setToolbarHidden((prevState) => {
      const newState = prevState.map(() => true);

      return newState;
    });
    if (onSave) {
      onSave(messages);
    }
  };

  // const handleClick = (idx) => {
  //   const newOptions = [...option];
  //   newOptions[idx] = true;
  //   setOption(newOptions);
  // };

  const handleClickHoverImg = (idx) => {
    const newOptions = [...optionImg];
    newOptions[idx] = true;
    setOptionImg(newOptions);
  };

  return (
    <>
      <Alert
        isWarning={true}
        isOpen={isDeleteItem}
        onClose={() => setIsDeleteItem(false)}
        onConfirm={() => handleDeleteItem(index, data, setData)}
        rightButtonText={"確認"}
        leftButtonText="取消"
        mainTitle={`確認刪除此文章區塊 ?`}
      />
      <div
        onMouseEnter={() => {
          setHoverBtn(true);
        }}
        onMouseLeave={() => {
          setHoverBtn(false);
          if (colorBtn) {
            handleSaveColor(nowChangeColor);
            setColorBtn(false);
          }
        }}
        className={styles.editorBlock}
        style={{ background: nowChangeColor }}
      >
        {htmlTextArr.map((_, idx) => (
          <div
            className={`${styles.editorBlockFlex} ${styles.width_100}`}
            key={idx}
            onMouseLeave={() => {
              const newOptions = [...option];
              newOptions[idx] = false;
              setOption(newOptions);
              // setLabelColorBtn(false);
            }}
          >
            <div
              onMouseEnter={() => {
                handleClickHoverImg(idx);
              }}
              onMouseLeave={() => {
                setOptionImg([false]);
              }}
              className={`${styles.imgContainer} ${styles.small}`}
            >
              <img src={previewImage[idx]} alt="introduction" />
              <div
                style={{
                  background:
                    Array.isArray(optionImg) && optionImg[idx] && "#0000004D",
                }}
                className={styles.imgUpdateBtn}
              >
                {
                  <div className={styles.uploadPreviewButton}>
                    <label>
                      <input
                        name="uploadPreview"
                        id="uploadPreview"
                        type="file"
                        size={16}
                        accept="image/*"
                        multiple
                        onChange={(e) => {
                          handleImageChange(e, setPreviewImage);
                        }}
                      />
                      {optionImg[idx] && (
                        <Button
                          icon={<MdOutlineUploadFile />}
                          type="secondary"
                          title="上傳檔案"
                          color="#676767"
                        />
                      )}
                    </label>
                  </div>
                }
              </div>
            </div>
            <div
              className={styles.width_67}
              onMouseLeave={() => {
                setOption([false]);
              }}
            >
              {/* <div
                style={{
                  background:
                    Array.isArray(nowLabelChangeColor) &&
                    nowLabelChangeColor[idx],
                }}
                className={styles.introduction}
                onMouseEnter={() => {
                  handleClick(idx);
                }}
              >
                簡介
              </div> */}
              {/* <div
                onMouseLeave={() => {
                  handleSaveLabelColor(idx, nowLabelChangeColor[idx]);
                }}
              >
                {option[idx] && (
                  <div className={styles.editorLabelOptionBtn}>
                    <div className={styles.optionBtnIcon}>
                      <IoColorFillOutline
                        onClick={() => {
                          setLabelColorBtn(true);
                        }}
                        fontSize={20}
                      />
                    </div>
                    {labelColorBtn && (
                      <ColorLabelPicker
                        data={nowLabelChangeColor}
                        idx={idx}
                        nowChangeColor={nowLabelChangeColor[idx]}
                        setNowChangeColor={setNowLabelChangeColor}
                      />
                    )}
                  </div>
                )}
              </div> */}

              <Editor
                toolbarHidden={toolbarHidden[idx]}
                ref={editorRef}
                toolbar={{
                  options: ["inline", "colorPicker", "textAlign"],
                  inline: {
                    options: ["bold", "italic", "underline"],
                  },
                }}
                onFocus={() => handleFocus(idx)}
                onBlur={() => handleBlur()}
                editorState={editorStates[idx]}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                onEditorStateChange={(newEditorState) =>
                  onEditorStateChange(newEditorState, idx)
                }
                readOnly={false}
              />
            </div>
          </div>
        ))}
        {hoverBtn && (
          <div className={styles.editorBlockOptionBtn}>
            <div className={styles.optionBtn}>
              <div
                onClick={() => handleMoveItemDown(index, data, setData)}
                className={styles.optionBtnIcon}
              >
                <IoChevronDownOutline fontSize={20} />
              </div>
            </div>
            <div className={styles.optionBtn}>
              <div
                onClick={() => handleMoveItemUp(index, data, setData)}
                className={styles.optionBtnIcon}
              >
                <IoChevronUp fontSize={20} />
              </div>
            </div>
            <div className={styles.optionBtn}>
              <div className={styles.optionBtnIcon}>
                <IoColorFillOutline
                  onClick={() => setColorBtn(true)}
                  fontSize={20}
                />
              </div>
            </div>
            <div className={styles.optionBtn}>
              <div
                onClick={() => setIsDeleteItem(true)}
                className={styles.optionBtnIcon}
              >
                <MdDeleteOutline color="#FF766E" fontSize={20} />
              </div>
            </div>
          </div>
        )}
        {colorBtn && hoverBtn && (
          <ColorPicker
            nowChangeColor={nowChangeColor}
            setNowChangeColor={setNowChangeColor}
          />
        )}
      </div>
    </>
  );
};

export const Template5Editor = ({
  htmlTextArr,
  onSave,
  onSaveTitle,
  index,
  data,
  setData,
  backGroundColor,
  handleImageChange,
  imgArr,
  htmlTitleTextArr,
  handleSaveColor,
}) => {
  const [editorStates, setEditorStates] = useState(
    htmlTextArr.map(() => EditorState.createEmpty())
  );

  const [editorTitleStates, setEditorTitleStates] = useState(
    htmlTitleTextArr.map(() => EditorState.createEmpty())
  );

  const [messagesTitle, setMessagesTitle] = useState(htmlTitleTextArr);

  const [messages, setMessages] = useState(htmlTextArr);
  const [isDeleteItem, setIsDeleteItem] = useState(false);
  const [hoverBtn, setHoverBtn] = useState(false);
  const [nowChangeColor, setNowChangeColor] = useState("#ffffff");

  const [previewImage, setPreviewImage] = useState([Template02, Template02]);

  const [colorBtn, setColorBtn] = useState(false);

  const [toolbarTitleHidden, setToolbarTitleHidden] = useState(true);

  const [toolbarHidden, setToolbarHidden] = useState(
    htmlTextArr.map(() => true)
  );

  const [optionImg, setOptionImg] = useState([false]);

  const editorTitleRef = useRef(null);
  const editorRef = useRef(null);

  useEffect(() => {
    const updatedTitleStates = htmlTitleTextArr.map((htmlMessage) => {
      const contentBlock = htmlToDraft(htmlMessage);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        return EditorState.createWithContent(contentState);
      } else {
        return EditorState.createWithContent();
      }
    });
    setEditorTitleStates(updatedTitleStates);
    setMessagesTitle(htmlTitleTextArr);
  }, [htmlTitleTextArr]);

  useEffect(() => {
    setNowChangeColor(backGroundColor);
  }, [backGroundColor]);

  useEffect(() => {
    setPreviewImage([imgArr[0], imgArr[1]]);
  }, [imgArr]);

  useEffect(() => {
    if (htmlTextArr && htmlTextArr.length > 0) {
      const newEditorStates = htmlTextArr.map((htmlText) => {
        const contentBlock = htmlToDraft(htmlText);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          return EditorState.createWithContent(contentState);
        } else {
          return EditorState.createWithContent();
        }
      });
      setEditorStates(newEditorStates);
      setMessages(htmlTextArr);
    }
  }, [htmlTextArr]);

  const onEditorStateChange = (newEditorState, idx) => {
    if (!newEditorState) return;
    const content = newEditorState.getCurrentContent();
    const newEditorStates = [...editorStates];
    const newMessages = [...messages];

    newEditorStates[idx] = newEditorState;
    newMessages[idx] = draftToHtml(convertToRaw(content));

    setEditorStates(newEditorStates);
    setMessages(newMessages);
  };

  const onEditorStateTitleChange = (newEditorState, idx) => {
    if (!newEditorState) return;
    const content = newEditorState.getCurrentContent();
    const updatedEditorStates = [...editorTitleStates];
    const updatedMessagesTitle = [...messagesTitle];

    updatedEditorStates[idx] = newEditorState;
    updatedMessagesTitle[idx] = draftToHtml(convertToRaw(content));

    setEditorTitleStates(updatedEditorStates);
    setMessagesTitle(updatedMessagesTitle);
  };

  const handleTitleFocus = () => {
    setToolbarTitleHidden(false);
  };

  const handleTitleBlur = () => {
    setToolbarTitleHidden(true);
    if (onSaveTitle) {
      if (messagesTitle[0] === '<p style="text-align:center;"></p>\n') {
        messagesTitle[0] = `<p style="text-align:center;"><span style="color: rgb(51,51,51);font-size: 28px;"></span></p>\n`;
      } else {
        messagesTitle[0] = addFontSizeIfNotExist(messagesTitle[0]);
      }
      onSaveTitle(messagesTitle);
    }
  };

  const handleFocus = (idx) => {
    setToolbarHidden((prevState) => {
      const newState = [...prevState];
      newState[idx] = false;
      return newState;
    });
  };

  const handleBlur = () => {
    setToolbarHidden((prevState) => {
      const newState = prevState.map(() => true);
      return newState;
    });
    if (onSave) {
      onSave(messages);
    }
  };

  const handleClickHoverImg = (idx) => {
    const newOptions = [...optionImg];
    newOptions[idx] = true;
    setOptionImg(newOptions);
  };

  return (
    <>
      <Alert
        isWarning={true}
        isOpen={isDeleteItem}
        onClose={() => setIsDeleteItem(false)}
        onConfirm={() => handleDeleteItem(index, data, setData)}
        rightButtonText={"確認"}
        leftButtonText="取消"
        mainTitle={`確認刪除此文章區塊 ?`}
      />
      <div
        onMouseEnter={() => {
          setHoverBtn(true);
        }}
        onMouseLeave={() => {
          setHoverBtn(false);
          if (colorBtn) {
            handleSaveColor(nowChangeColor);
            setColorBtn(false);
          }
        }}
        className={styles.editorBlock}
        style={{ background: nowChangeColor }}
      >
        {htmlTitleTextArr.map((_, idx) => (
          <div key={idx}>
            <Editor
              toolbarHidden={toolbarTitleHidden}
              ref={editorTitleRef}
              editorState={editorTitleStates[idx]}
              onEditorStateChange={(newEditorState) =>
                onEditorStateTitleChange(newEditorState, idx)
              }
              onFocus={handleTitleFocus}
              onBlur={handleTitleBlur}
              readOnly={false}
              toolbar={{
                options: ["fontSize", "textAlign"],
                fontSize: {
                  options: ["24","28", "32"],
                },
                inline: {
                  options: [],
                },
              }}
            />
          </div>
        ))}
        <div className={`${styles.editorBlockFlex} ${styles.width_100}`}>
          {htmlTextArr.map((_, idx) => (
            <div className={styles.width_48Flex} key={idx}>
              <div
                onMouseEnter={() => {
                  handleClickHoverImg(idx);
                }}
                onMouseLeave={() => {
                  setOptionImg([false]);
                }}
                className={`${styles.imgContainer} ${styles.width_45}`}
              >
                <img src={previewImage[idx]} alt="introduction" />
                <div
                  style={{
                    background:
                      Array.isArray(optionImg) && optionImg[idx] && "#0000004D",
                  }}
                  className={styles.imgUpdateBtn}
                >
                  <div className={styles.uploadPreviewButton}>
                    <label>
                      <input
                        name="uploadPreview"
                        id="uploadPreview"
                        type="file"
                        size={16}
                        accept="image/*"
                        multiple
                        onChange={(e) => {
                          handleImageChange(e, setPreviewImage, idx);
                        }}
                      />
                      {optionImg[idx] && (
                        <Button
                          icon={<MdOutlineUploadFile />}
                          type="secondary"
                          title="上傳檔案"
                          color="#676767"
                        />
                      )}
                    </label>
                  </div>
                </div>
              </div>
              <div className={styles.width_50}>
                <Editor
                  toolbarHidden={toolbarHidden[idx]}
                  ref={editorRef}
                  toolbar={{
                    options: ["inline", "colorPicker", "textAlign"],
                    inline: {
                      options: ["bold", "italic", "underline"],
                    },
                  }}
                  onFocus={() => handleFocus(idx)}
                  onBlur={() => handleBlur()}
                  editorState={editorStates[idx]}
                  wrapperClassName="demo-wrapper"
                  editorClassName="demo-editor"
                  onEditorStateChange={(newEditorState) =>
                    onEditorStateChange(newEditorState, idx)
                  }
                  readOnly={false}
                />
              </div>
            </div>
          ))}
        </div>
        {hoverBtn && (
          <div className={styles.editorBlockOptionBtn}>
            <div className={styles.optionBtn}>
              <div
                onClick={() => handleMoveItemDown(index, data, setData)}
                className={styles.optionBtnIcon}
              >
                <IoChevronDownOutline fontSize={20} />
              </div>
            </div>
            <div className={styles.optionBtn}>
              <div
                onClick={() => handleMoveItemUp(index, data, setData)}
                className={styles.optionBtnIcon}
              >
                <IoChevronUp fontSize={20} />
              </div>
            </div>
            <div className={styles.optionBtn}>
              <div className={styles.optionBtnIcon}>
                <IoColorFillOutline
                  onClick={() => setColorBtn(true)}
                  fontSize={20}
                />
              </div>
            </div>
            <div className={styles.optionBtn}>
              <div
                onClick={() => setIsDeleteItem(true)}
                className={styles.optionBtnIcon}
              >
                <MdDeleteOutline color="#FF766E" fontSize={20} />
              </div>
            </div>
          </div>
        )}
        {colorBtn && hoverBtn && (
          <ColorPicker
            nowChangeColor={nowChangeColor}
            setNowChangeColor={setNowChangeColor}
          />
        )}
      </div>
    </>
  );
};

export const Template6Editor = ({
  htmlTextArr,
  onSave,
  onSaveTitle,
  index,
  data,
  setData,
  backGroundColor,
  handleImageChange,
  imgArr,
  htmlTitleTextArr,
  handleSaveColor,
}) => {
  const [editorStates, setEditorStates] = useState(
    htmlTextArr.map(() => EditorState.createEmpty())
  );

  const [editorTitleStates, setEditorTitleStates] = useState(
    htmlTitleTextArr.map(() => EditorState.createEmpty())
  );

  const [messagesTitle, setMessagesTitle] = useState(htmlTitleTextArr);

  const [messages, setMessages] = useState(htmlTextArr);
  const [isDeleteItem, setIsDeleteItem] = useState(false);
  const [hoverBtn, setHoverBtn] = useState(false);
  const [nowChangeColor, setNowChangeColor] = useState("#ffffff");

  const [previewImage, setPreviewImage] = useState([
    Template01,
    Template01,
    Template01,
  ]);

  const [colorBtn, setColorBtn] = useState(false);

  const [toolbarTitleHidden, setToolbarTitleHidden] = useState(true);

  const [toolbarHidden, setToolbarHidden] = useState(
    htmlTextArr.map(() => true)
  );

  const [optionImg, setOptionImg] = useState([false]);

  const editorTitleRef = useRef(null);
  const editorRef = useRef(null);

  useEffect(() => {
    const updatedTitleStates = htmlTitleTextArr.map((htmlMessage) => {
      const contentBlock = htmlToDraft(htmlMessage);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        return EditorState.createWithContent(contentState);
      } else {
        return EditorState.createWithContent();
      }
    });
    setEditorTitleStates(updatedTitleStates);
    setMessagesTitle(htmlTitleTextArr);
  }, [htmlTitleTextArr]);

  useEffect(() => {
    setNowChangeColor(backGroundColor);
  }, [backGroundColor]);

  useEffect(() => {
    setPreviewImage([imgArr[0], imgArr[1], imgArr[2]]);
  }, [imgArr]);

  useEffect(() => {
    if (htmlTextArr && htmlTextArr.length > 0) {
      const newEditorStates = htmlTextArr.map((htmlText) => {
        const contentBlock = htmlToDraft(htmlText);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          return EditorState.createWithContent(contentState);
        } else {
          return EditorState.createWithContent();
        }
      });
      setEditorStates(newEditorStates);
      setMessages(htmlTextArr);
    }
  }, [htmlTextArr]);

  const onEditorStateChange = (newEditorState, idx) => {
    if (!newEditorState) return;
    const content = newEditorState.getCurrentContent();
    const newEditorStates = [...editorStates];
    const newMessages = [...messages];

    newEditorStates[idx] = newEditorState;
    newMessages[idx] = draftToHtml(convertToRaw(content));

    setEditorStates(newEditorStates);
    setMessages(newMessages);
  };

  const onEditorStateTitleChange = (newEditorState, idx) => {
    if (!newEditorState) return;

    const content = newEditorState.getCurrentContent();
    const updatedEditorStates = [...editorTitleStates];
    const updatedMessagesTitle = [...messagesTitle];

    updatedEditorStates[idx] = newEditorState;
    updatedMessagesTitle[idx] = draftToHtml(convertToRaw(content));

    setEditorTitleStates(updatedEditorStates);
    setMessagesTitle(updatedMessagesTitle);
  };

  const handleTitleBlur = () => {
    setToolbarTitleHidden(true);
    if (onSaveTitle) {
      if (messagesTitle[0] === '<p style="text-align:center;"></p>\n') {
        messagesTitle[0] = `<p style="text-align:center;"><span style="color: rgb(51,51,51);font-size: 28px;"></span></p>\n`;
      } else {
        messagesTitle[0] = addFontSizeIfNotExist(messagesTitle[0]);
      }

      onSaveTitle(messagesTitle);
    }
  };

  const handleTitleFocus = () => {
    setToolbarTitleHidden(false);
  };

  const handleFocus = (idx) => {
    setToolbarHidden((prevState) => {
      const newState = [...prevState];
      newState[idx] = false;
      return newState;
    });
  };

  const handleBlur = () => {
    setToolbarHidden((prevState) => {
      const newState = prevState.map(() => true);
      return newState;
    });
    if (onSave) {
      onSave(messages);
    }
  };

  const handleClickHoverImg = (idx) => {
    const newOptions = [...optionImg];
    newOptions[idx] = true;
    setOptionImg(newOptions);
  };

  return (
    <>
      <Alert
        isWarning={true}
        isOpen={isDeleteItem}
        onClose={() => setIsDeleteItem(false)}
        onConfirm={() => handleDeleteItem(index, data, setData)}
        rightButtonText={"確認"}
        leftButtonText="取消"
        mainTitle={`確認刪除此文章區塊 ?`}
      />
      <div
        onMouseEnter={() => {
          setHoverBtn(true);
        }}
        onMouseLeave={() => {
          setHoverBtn(false);
          if (colorBtn) {
            handleSaveColor(nowChangeColor);
            setColorBtn(false);
          }
        }}
        className={styles.editorBlock}
        style={{ background: nowChangeColor }}
      >
        {htmlTitleTextArr.map((_, idx) => (
          <div key={idx}>
            <Editor
              toolbarHidden={toolbarTitleHidden}
              ref={editorTitleRef}
              editorState={editorTitleStates[idx]}
              onEditorStateChange={(newEditorState) =>
                onEditorStateTitleChange(newEditorState, idx)
              }
              onFocus={handleTitleFocus}
              onBlur={handleTitleBlur}
              readOnly={false}
              toolbar={{
                options: ["fontSize", "textAlign"],
                fontSize: {
                  options: ["24","28", "32"],
                },
                inline: {
                  options: [],
                },
              }}
            />
          </div>
        ))}
        <div className={styles.editorBlockFlex}>
          {htmlTextArr.map((_, idx) => (
            <div className={styles.width_29} key={idx}>
              <div
                style={{ width: "100%" }}
                onMouseEnter={() => {
                  handleClickHoverImg(idx);
                }}
                onMouseLeave={() => {
                  setOptionImg([false]);
                }}
                className={`${styles.imgContainer} ${styles.small}`}
              >
                <img src={previewImage[idx]} alt="introduction" />
                <div
                  style={{
                    background: optionImg[idx] && "#0000004D",
                  }}
                  className={styles.imgUpdateBtn}
                >
                  <div className={styles.uploadPreviewButton}>
                    <label>
                      <input
                        name="uploadPreview"
                        id="uploadPreview"
                        type="file"
                        size={16}
                        accept="image/*"
                        multiple
                        onChange={(e) => {
                          handleImageChange(e, setPreviewImage, idx);
                        }}
                      />
                      {optionImg[idx] && (
                        <Button
                          icon={<MdOutlineUploadFile />}
                          type="secondary"
                          title="上傳檔案"
                          color="#676767"
                        />
                      )}
                    </label>
                  </div>
                </div>
              </div>
              <Editor
                toolbarHidden={toolbarHidden[idx]}
                ref={editorRef}
                toolbar={{
                  options: ["inline", "colorPicker", "textAlign"],
                  inline: {
                    options: ["bold", "italic", "underline"],
                  },
                }}
                onFocus={() => handleFocus(idx)}
                onBlur={() => handleBlur()}
                editorState={editorStates[idx]}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                onEditorStateChange={(newEditorState) =>
                  onEditorStateChange(newEditorState, idx)
                }
                readOnly={false}
              />
            </div>
          ))}
        </div>
        {hoverBtn && (
          <div className={styles.editorBlockOptionBtn}>
            <div className={styles.optionBtn}>
              <div
                onClick={() => handleMoveItemDown(index, data, setData)}
                className={styles.optionBtnIcon}
              >
                <IoChevronDownOutline fontSize={20} />
              </div>
            </div>
            <div className={styles.optionBtn}>
              <div
                onClick={() => handleMoveItemUp(index, data, setData)}
                className={styles.optionBtnIcon}
              >
                <IoChevronUp fontSize={20} />
              </div>
            </div>
            <div className={styles.optionBtn}>
              <div className={styles.optionBtnIcon}>
                <IoColorFillOutline
                  onClick={() => setColorBtn(true)}
                  fontSize={20}
                />
              </div>
            </div>
            <div className={styles.optionBtn}>
              <div
                onClick={() => setIsDeleteItem(true)}
                className={styles.optionBtnIcon}
              >
                <MdDeleteOutline color="#FF766E" fontSize={20} />
              </div>
            </div>
          </div>
        )}
        {colorBtn && hoverBtn && (
          <ColorPicker
            nowChangeColor={nowChangeColor}
            setNowChangeColor={setNowChangeColor}
          />
        )}
      </div>
    </>
  );
};
