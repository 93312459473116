import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  sendGetClientRequest,
  sendPutClientRequest,
  sendDeleteClientRequest,
  sendPostClientRequestForArticle,
  sendPostEditClientRequestForArticle,
} from "../../api/helper";

export const putVerifyEmail = createAsyncThunk(
  "user/putVerifyEmail",
  async (parameters) => {
    const response = await sendPutClientRequest(
      `/api/enterprise_management/${parameters.enterprise_id}/verify_mail`
    );
    return response;
  }
);

export const getEnterpriseList = createAsyncThunk(
  "user/getEnterpriseList",
  async (parameters) => {
    let responseUrl;
    if (
      parameters.service &&
      parameters.keyword &&
      parameters.currentPage &&
      parameters.limit
    ) {
      responseUrl = `/api/enterprise_management?keyword=${parameters.keyword}&currentPage=${parameters.currentPage}&limit=${parameters.limit}&service=${parameters.service}`;
    } else if (
      parameters.service &&
      parameters.currentPage &&
      parameters.limit
    ) {
      responseUrl = `/api/enterprise_management?currentPage=${parameters.currentPage}&limit=${parameters.limit}&service=${parameters.service}`;
    } else if (
      parameters.keyword &&
      parameters.currentPage &&
      parameters.limit
    ) {
      responseUrl = `/api/enterprise_management?currentPage=${parameters.currentPage}&limit=${parameters.limit}&keyword=${parameters.keyword}`;
    } else if (parameters.currentPage && parameters.limit) {
      responseUrl = `/api/enterprise_management?currentPage=${parameters.currentPage}&limit=${parameters.limit}`;
    }
    const response = await sendGetClientRequest(responseUrl);
    return response;
  }
);

export const getEnterpriseManagement = createAsyncThunk(
  "user/getEnterpriseManagement",
  async (parameters) => {
    const response = await sendGetClientRequest(
      `/api/enterprise_management/${parameters.enterprise_id}`,
      parameters
    );
    return response.data;
  }
);

export const getEnterpriseUsers = createAsyncThunk(
  "user/getEnterpriseUsers",
  async (parameters) => {
    let query = `/api/enterprise_management/${parameters.enterprise_id}/users?page=${parameters.page}&rows=${parameters.limit}`;
    if (parameters.enable) query += `&enable=${parameters.enable}`;
    if (parameters.search) query += `&search=${parameters.search}`;
    const response = await sendGetClientRequest(query);
    return response.data;
  }
);

export const getEnterpriseArticle = createAsyncThunk(
  "user/getEnterpriseArticle",
  async (parameters) => {
    let query = `/api/enterprise_case?page=${parameters.page}&limit=${parameters.limit}`;
    if (parameters.search) query += `&search=${parameters.search}`;
    const response = await sendGetClientRequest(query);
    return response.data;
  }
);

export const getAloneEnterpriseArticle = createAsyncThunk(
  "user/getAloneEnterpriseArticle",
  async (id) => {    
    const response = await sendGetClientRequest(
      `/api/enterprise_case/${id}`,
    );
    return response.data;
  }
);

export const addArticle = createAsyncThunk(
  "user/addArticle",
  async (parameters) => {    
    const response = await sendPostClientRequestForArticle(
      `/api/enterprise_case`,
      parameters,
    );
    return response.data;
  }
);

export const editArticle = createAsyncThunk(
  "user/editArticle",
  async (parameters) => {    
    const response = await sendPostEditClientRequestForArticle(
      `/api/enterprise_case/${parameters.id}`,
      parameters,
    );
    return response.data;
  }
);

export const displayArticle = createAsyncThunk(
  "user/displayArticle",
  async (parameters) => {    
    const response = await sendPutClientRequest(
      `/api/enterprise_case/${parameters.id}/display`,parameters
    );
    return response;
  }
);

export const deleteArticle = createAsyncThunk(
  "user/putVerifyEmail",
  async (id) => {
    const response = await sendDeleteClientRequest(
      `/api/enterprise_case/${id}`
    );
    return response;
  }
);

const enterpriseSlice = createSlice({
  name: "enterprise",
  initialState: {
    enterpriseListsData: [],
    enterpriseListsLoading: "notLoading",
    service_lists: [],
    active_service: "全部",
    management_users: [],
    enterpriseShareLoading: false,
    enterpriseShareList: [],
    isEnterprise:false,
  },
  reducers: {
    updateActiveService: (state, action) => {
      state.active_service = action.payload;
    },
    closeLoading: (state) => {
      state.enterpriseShareLoading = false;
    },
    changeIsEnterprise: (state,action) => {
      state.isEnterprise = action.payload;
    },
  },
  extraReducers: {
    // GET ENTERPRISE SERVICE MANAGEMENT
    [putVerifyEmail.pending]: () => {},
    [putVerifyEmail.fulfilled]: (state) => {
      state.success = true;
    },
    [putVerifyEmail.rejected]: () => {},

    // GET ENTERPRISE LISTS
    [getEnterpriseList.pending]: (state) => {
      state.enterpriseListsData = [];
      state.enterpriseListsLoading = "loading";
    },
    [getEnterpriseList.fulfilled]: (state, { payload }) => {
      if (payload?.data) {
        state.enterpriseListsData = payload.data;
        state.enterpriseListsLoading = "doneLoading";
      } else {
        state.enterpriseListsData = [];
        state.enterpriseListsLoading = "loadingfailed";
      }
    },
    [getEnterpriseList.rejected]: (state) => {
      state.enterpriseListsData = [];
      state.enterpriseListsLoading = "notLoading";
    },

    // GET ENTERPRISE SERVICE MANAGEMENT
    [getEnterpriseManagement.pending]: (state) => {
      state.loading = true;
    },
    [getEnterpriseManagement.fulfilled]: (state, { payload }) => {
      if (payload?.data) {
        state.service_lists = payload.data;
        state.loading = false;
      }
    },
    [getEnterpriseManagement.rejected]: (state) => {
      state.loading = false;
    },

    // GET ENTERPRISE USERS
    [getEnterpriseUsers.pending]: (state) => {
      state.loading = true;
    },
    [getEnterpriseUsers.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.management_users = payload;
        state.loading = false;
      }
    },
    [getEnterpriseUsers.rejected]: (state) => {
      state.loading = false;
    },

    // GET getAloneEnterprise
    [getAloneEnterpriseArticle.pending]: (state) => {
      state.enterpriseShareLoading = true;
    },
    [getAloneEnterpriseArticle.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.enterpriseShareLoading = false;
      }
    },
    [getAloneEnterpriseArticle.rejected]: (state) => {
      state.enterpriseShareLoading = false;
    },

    // GET getEnterpriseArticle
    [getEnterpriseArticle.pending]: (state) => {
      state.enterpriseShareLoading = true;
    },
    [getEnterpriseArticle.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.enterpriseShareList = payload;
        state.enterpriseShareLoading = false;
      }
    },
    [getEnterpriseArticle.rejected]: (state) => {
      state.enterpriseShareLoading = false;
    },

    [addArticle.pending]: (state) => {
      state.enterpriseShareLoading = true;
    },
    [addArticle.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.enterpriseShareLoading = false;
      }
    },
    [addArticle.rejected]: (state) => {
      state.enterpriseShareLoading = false;
    },

    [editArticle.pending]: (state) => {
      state.enterpriseShareLoading = true;
    },
    [editArticle.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.enterpriseShareLoading = false;
      }
    },
    [editArticle.rejected]: (state) => {
      state.enterpriseShareLoading = false;
    },
  },
});
export default enterpriseSlice.reducer;
export const selectEnterprise = (state) => state.enterpriseLists;
export const { updateActiveService,closeLoading,changeIsEnterprise } = enterpriseSlice.actions;
